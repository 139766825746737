import React from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioHow from "../elements/portfolio/PortfolioHow";
import Typed from "react-typed";

const GettingStarted = () => {
  return (
    <>
      <SEO title="LearningQ | Getting Started" />
      <Layout>
        <div className="main-content">
          <div className="slider-area height-550  ">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content">
                    {" "}
                    <h1 className="title theme-gradient display-two">
                      How to <br />{" "}
                      <Typed
                        strings={["Engage.", "Grow.", "LearningQ."]}
                        typeSpeed={80}
                        backSpeed={5}
                        backDelay={1000}
                        loopCount={1}
                      />
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className="mb--10">
                    At LearningQ we are dedicated to providing a learning
                    environment that is effective, intuitive, and flexible. To
                    do that we use micro-learning to break down complex topics
                    into skills that learners can access and review at their own
                    pace.
                  </p>
                  <p className="mb--10">
                    The core of what we do is engagement. Our tools and content
                    are designed to help learners develop a habit of
                    self-improvement, by being both informative and
                    entertaining.
                  </p>
                  <p className="mb--10">
                    We understand that adapting new technology is primarily an
                    investment in time. We strive to make the getting started
                    simple and direct for both the participant and their
                    supports.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-area " data-black-overlay="2">
            <div className="container">
              <div className="row">
                <div className="rn-section-gap-sml">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Portfolio Three Column"
                    title="One Platform, Many Uses"
                    description="We've developed a great way to help people develop their skills, and our partners are finding all sorts of ways to make use of it. Below are a few of the ways to put us to work in your organization, and steps on how to get started."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container rn-section-gapBottom-sml">
            <PortfolioHow Column="col-lg-4 col-md-6 mt--30 portfolio" />
          </div>
        </div>
      </Layout>
    </>
  );
};
export default GettingStarted;
