import React from "react";
import { Link } from "react-router-dom";

const PortfolioProduct = ({ portfolio }) => {
  console.log(portfolio);
  return (
    <div className="rwt-card">
      <div className="inner">
        <div className="thumbnail">
          <figure className="card-image">
            <Link to={`/products/${portfolio.location}`}>
              <img
                className="img-fluid"
                src={portfolio.image}
                alt="Portfolio-01"
              />
            </Link>
          </figure>
          <Link
            to={`/products/${portfolio.location}`}
            className="rwt-overlay"
          ></Link>
        </div>
        <div className="content">
          <h5 className="title mb--10 text-capitalize">
            <Link to={`/products/${portfolio.location}`}>{portfolio.name}</Link>
          </h5>
        </div>
      </div>
    </div>
  );
};
export default PortfolioProduct;
