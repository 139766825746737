import React from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterFour from "../common/footer/FooterThreeAlt";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Portfolio from "../elements/portfolio/Portfolio";
import PricingThree from "../elements/pricing/PricingThree";
import Typed from "react-typed";
import PortfolioProducts from "../elements/portfolio/PortfolioProducts";
import Modal from "../elements/modal/Modal";
import DemoSignUp from "../elements/forms/DemoSignUp";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";

const Products = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  function showModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }
  return (
    <>
      <SEO title="LearningQ | Healthy Cooking Basics" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
        {/* Start Slider Area  */}
        <div
          className="container bg_image bg_image_fixed height-650"
          data-black-overlay="4"
          style={{
            maxWidth: "100%",
            padding: "0",
            margin: "0",
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-left">
                  <h1 className="title theme-gradient display-two">
                    Engaging Content <br /> For{" "}
                    <Typed
                      strings={["Instructors.", "Participants.", "Everyone."]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    We belive that training begins with active participation. We
                    strive to make every skill unit interesting and effective.
                    We want everone who uses LearningQ to be excited for what
                    comes next.{" "}
                  </p>
                </div>
                <div className="button-group mt--30 mt_sm--20">
                  <button className="btn-default" onClick={showModal}>
                    Reqest a Free Trial{" "}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Portfolio Area  */}
        <div className="rwt-portfolio-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded="2"
                  subtitle="Project"
                  title="Certifications"
                  description=""
                />
              </div>
            </div>
            <PortfolioProducts Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
          </div>
        </div>
        {/* End Portfolio Area  */}
        <Separator />
        <div
          className="height-650  bg_image bg_image_fixed"
          data-white-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <Portfolio />
          </div>
        </div>
        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--35">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Affordable Pricing Plan."
                  description="We help organizations succeed by extending services, <br /> optimizing coaching, and increasing engagement."
                />
              </div>
            </div>
            <PricingThree />
          </div>
        </div>
        {/* End Elements Area  */}
        <FooterFour />
      </main>
      <Modal isOpen={isModalOpen} title="Grant Sign Up" onClose={closeModal}>
        <DemoSignUp></DemoSignUp>
      </Modal>
    </>
  );
};

export default Products;
