import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionGrantPartner = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            What do we need to commit to LearningQ?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            LearningQ is eager to provide turn-key solutions to integrate with
            your existing programming. We expect it will take 30 minutes for
            instructors to be onboarded onto LearningQ, understand how to assign
            courses and see the results of the quizzes. Our partners can choose
            to charge for the LearningQ platform, but we will not be receiving
            any of those funds. Your instructors and the program participants
            will have access to the platform through August, 2024.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            What are the benefits of participating?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            You will have access to the LearningQ platform and content, for the
            participating instructors and learners for the duration of the
            scholarship period.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            How much time do we need to commit to this?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            We are looking for classes that meet at least once a week durring
            the testing period.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            What do you need from us to participate?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            During early discussions, we will ask for a rough number of
            participants you think would be a good target for this program.
            Eventually, LearningQ will need either a phone number or an email
            address to set up each participant with a unique account.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionGrantPartner;
