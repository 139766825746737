import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import ReactPlayer from "react-player";

const TabThree = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Building a Profile and Resume</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>I Want a Job</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Interviewing</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>The Job Hunt</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-8">
                              <div className="section-title">
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    className="react-player"
                                    url="https://vimeo.com/819641864"
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div className="card-box card-style-1 text-left">
                                    <div className="inner">
                                      <div className="content">
                                        <h4 className="title mb--20">
                                          Skill Units
                                        </h4>
                                        <ul>
                                          <li>
                                            Introduction Emails & Cover letters
                                          </li>
                                          <li>Building an Online Profile</li>
                                          <li>
                                            Completing an Online Application
                                          </li>
                                          <li>Creating a Traditional Resume</li>
                                          <li>
                                            Working with a Staffing Agency
                                          </li>
                                        </ul>
                                        <h4 className="title mb--20">
                                          Total Time - 6:07
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-8">
                              <div className="section-title">
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    className="react-player"
                                    url="https://vimeo.com/819634201"
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div className="card-box card-style-1 text-left">
                                    <div className="inner">
                                      <div className="content">
                                        <h4 className="title mb--20">
                                          Skill Units
                                        </h4>
                                        <ul>
                                          <li>Building Healthy Habits</li>
                                          <li>How to search for a Job</li>
                                          <li>What's Expected from a Job</li>
                                          <li>
                                            How to Prepare for an Interview*
                                          </li>
                                          <li>Building Your Skills</li>
                                          <li>
                                            Building & Keeping a Work Schedule
                                          </li>
                                          <li>Assessing your Skills</li>
                                          <li>Challenges at Work</li>
                                          <li>
                                            Who and How to ask for help at work
                                          </li>
                                          <li>Is this Job Right for Me?</li>
                                          <li>
                                            Using your network to get a job
                                          </li>
                                        </ul>
                                        <h4 className="title mb--20">
                                          Total Time - 10:50
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-8">
                              <div className="section-title">
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    className="react-player"
                                    url="https://vimeo.com/819634128"
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div className="card-box card-style-1 text-left">
                                    <div className="inner">
                                      <div className="content">
                                        <h4 className="title mb--20">
                                          Skill Units
                                        </h4>
                                        <ul>
                                          <li>
                                            Asking and Answering Interview
                                            Questions
                                          </li>
                                          <li>Attitude</li>
                                          <li>Common Interview Questions</li>
                                          <li>Conversation Etiquette</li>
                                          <li>Follow Ups and Thank Yous</li>
                                          <li>
                                            How to Prepare for an Interview
                                          </li>
                                          <li>
                                            Introduction Emails and Cover
                                            Letters
                                          </li>
                                          <li>Negotiating a Job Offer</li>
                                          <li>
                                            Using Your Network to get a Job
                                          </li>
                                        </ul>
                                        <h4 className="title mb--20">
                                          Total Time - 10:14
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-8">
                              <div className="section-title">
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    className="react-player"
                                    url="https://vimeo.com/819634201"
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div className="card-box card-style-1 text-left">
                                    <div className="inner">
                                      <div className="content">
                                        <h4 className="title mb--20">
                                          Skill Units
                                        </h4>
                                        <ul>
                                          <li>Building Your Skills</li>
                                          <li>Attitude</li>
                                          <li>Follow Ups and Thank Yous</li>
                                          <li>How To Search for a Job</li>

                                          <li>
                                            How to Prepare for an Interview
                                          </li>
                                          <li>
                                            Introduction Emails and Cover
                                            Letters
                                          </li>
                                          <li>Negotiating a Job Offer</li>
                                          <li>
                                            Using Your Network to get a Job
                                          </li>
                                          <li>
                                            Working with a Staffing Agency
                                          </li>
                                        </ul>

                                        <h4 className="title mb--20">
                                          Total Time - 8:52
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabThree;
