import React, { useState, useEffect } from "react";
import ProductsData from "../../data/portfolio/ProductsData.json";
import PortfolioProduct from "./PortfolioProduct";

const filters = [
  {
    id: 1,
    text: "All",
  },
  {
    id: 2,
    text: "Workforce",
  },
  {
    id: 3,
    text: "Health",
  },
];

const alldata = ProductsData;
const PortfolioProducts = ({ Column }) => {
  const [getAllItems] = useState(alldata);
  const [dataVisibleCount] = useState(6);

  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, [getAllItems, dataVisibleCount]);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category === e.target.textContent.toLowerCase() &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20">
            {filters.map((filter) => (
              <li className="list-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter ? "current" : " "
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="row row--15">
        {visibleItems.map((item) => (
          <div key={item.id} className={Column}>
            <PortfolioProduct portfolio={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default PortfolioProducts;
