import { useState, useRef } from "react";
import Logo from "../../elements/logo/Logo";
import MobileMenu from "./MobileMenu";
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";

const HeaderGrant = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  const ref = useRef();
  let [check] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;

  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default header-left-align ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-9 col-md-6 col-4 position-static">
              <div className="header-left d-flex">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                  image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-8">
              <div className="header-right">
                <div className="mobile-menu-bar ml--5 d-block d-lg-none"></div>
                <Darkmode />
                <div className="logo">
                  <a
                    href="https://www.aspiroinc.org/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img
                      className="logo-dark"
                      src={`${process.env.PUBLIC_URL}/images/brand/aspiro.png`}
                      alt="Aspiro Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderGrant;
