import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const TimelineThree = ({ data }) => {
  const TimelineData = data;
  console.log("Tineline data=" + TimelineData);
  return (
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0 justify-content-center">
        {/* Start Single Progress */}
        {TimelineData.map((data, index) => (
          <div className={`col-lg-2 col-md-2 rn-timeline-single`} key={index}>
            <div className="rn-timeline">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h6 className="title">{data.title}</h6>
              </ScrollAnimation>
              <div className="progress-line">
                <div className="line-inner"></div>
              </div>
              <div className="progress-dot">
                <div className="dot-level">
                  <div className="dot-inner"></div>
                </div>
              </div>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  );
};
export default TimelineThree;
