import React from "react";

const BrandList = [
 
  {
    image: "./images/brand/iddsafe.png",
  },
  {
    image: "./images/brand/DRTC.png",
  },
  {
    image: "./images/brand/LearningQ.png",
  }
];

const BrandOne = ({ brandStyle }) => {
  return (
    <ul className={`brand-list ${brandStyle}`}>
      {BrandList.map((data, index) => (
        <li key={index} style={{padding:"20px"}}>
          <img src={`${data.image}`} alt="Partner Logo" />
        </li>
      ))}
    </ul>
  );
};

export default BrandOne;
