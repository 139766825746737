import React from "react";
import { FiCheck, FiArrowRight } from "react-icons/fi";
import Modal from "../../elements/modal/Modal";
import { useState } from "react";
import MainSignUp from "../forms/MainSignUp";

const PricingThree = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  function showModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }
  return (
    <div className="row">
      <div className="col-lg-8 offset-lg-2">
        <div className="row row--0">
          {/* Start PRicing Table Area  */}
          <div className="col-lg-6 col-md-6 col-12">
            <div className="rn-pricing style-2">
              <div className="pricing-table-inner">
                <div className="pricing-header">
                  <h4 className="title">Monthly</h4>
                  <div className="pricing">
                    <div className="price-wrapper">
                      <span className="currency">$</span>
                      <span className="price">40</span>
                    </div>
                    <span className="subtitle">USD/Seat/Month</span>
                  </div>
                </div>
                <div className="pricing-body">
                  <ul className="list-style--1">
                    <li>
                      <FiCheck /> Job of Getting a Job Certification
                    </li>
                    <li>
                      <FiCheck /> Workforce Basics Certification
                    </li>
                    <li>
                      <FiCheck /> Healthy Cooking Basics Certification
                    </li>
                    <li>
                      <FiCheck /> Unlimted Access and Replays
                    </li>
                    <li>
                      <FiCheck /> Skill Checks and Tracking
                    </li>
                  </ul>
                </div>
                <div className="pricing-footer">
                  <button className="btn-default" onClick={showModal}>
                    Get Started{" "}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End PRicing Table Area  */}

          {/* Start PRicing Table Area  */}
          <div className="col-lg-6 col-md-6 col-12">
            <div className="rn-pricing style-2 active">
              <div className="pricing-table-inner">
                <div className="pricing-header">
                  <h4 className="title">Annual</h4>
                  <div className="pricing">
                    <div className="price-wrapper">
                      <span className="currency">$</span>
                      <span className="price">35</span>
                    </div>
                    <span className="subtitle">USD/Seat/Month</span>
                  </div>
                </div>
                <div className="pricing-body">
                  <ul className="list-style--1">
                    <li>
                      <FiCheck /> Job of Getting a Job Certification
                    </li>
                    <li>
                      <FiCheck /> Workforce Basics Certification
                    </li>
                    <li>
                      <FiCheck /> Healthy Cooking Basics Certification
                    </li>
                    <li>
                      <FiCheck /> Unlimted Access and Replays
                    </li>
                    <li>
                      <FiCheck /> Skill Checks and Tracking
                    </li>
                  </ul>
                </div>
                <div className="pricing-footer">
                  <button className="btn-default" onClick={showModal}>
                    Get Started{" "}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End PRicing Table Area  */}
        </div>
      </div>
      <Modal isOpen={isModalOpen} title="Grant Sign Up" onClose={closeModal}>
        <MainSignUp></MainSignUp>
      </Modal>
    </div>
  );
};
export default PricingThree;
