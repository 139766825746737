import React from "react";
import TrackVisibility from "react-on-screen";

import { Link } from "react-router-dom";

const SlipFour = () => {
  return (
    <div className="rn-splite-style bg-color-blackest">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center ">
          <div
            className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12 height-750 bg_image "
            data-black-overlay="5"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/split/agency.jpg)`,
              backgroundAttachment: "local",
              backgroundSize: "cover",
            }}
          >
            <div
              className="split-inner text-center"
              style={{ margin: "0 auto" }}
            >
              <h2 className="title" style={{ color: "#EE6C4D" }}>
                AGENCIES & INSTRUCTORS
              </h2>{" "}
              <h5 style={{ color: "#FFF" }}>
                Eager to find new ways to enhance your programming?
              </h5>
              <div className="content text-center">
                <TrackVisibility once>
                  <Link
                    className={`btn-default`}
                    style={{ margin: "0 auto" }}
                    to="/grant/grantpartners"
                  >
                    Find Out More!
                  </Link>
                </TrackVisibility>
              </div>
            </div>
          </div>
          <div
            className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12 height-750 bg_image"
            data-white-overlay="5"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/split/participant.jpg)`,
              backgroundAttachment: "local",
              backgroundSize: "cover",
            }}
          >
            <div
              className="split-inner text-center"
              style={{ margin: "0 auto" }}
            >
              <h2 className="title" style={{ color: "#EE6C4D" }}>
                INDIVIDUALS & FAMILIES
              </h2>{" "}
              <h5>Want access to the best skills training?</h5>
              <div className="content text-center">
                <TrackVisibility once>
                  <Link
                    className={`btn-default`}
                    style={{ margin: "0 auto" }}
                    to="/grant/Student"
                  >
                    Find Out More!
                  </Link>
                </TrackVisibility>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SlipFour;
