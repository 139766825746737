import React from "react";
import Timeline from "../../elements/timeline/TimelineThree";
import PricingThree from "../../elements/pricing/PricingThree";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";

const HowToDetailsContent = ({ data }) => {
  return (
    <div className="rwt-portfolio-details rn-section-gap">
      <div className="container">
        <div className="row">
          {/* Start Image Area  */}
          <div className="col-lg-10 offset-lg-1">
            <div className="inner">
              <div className="details-list">
                <div className="thumbnail alignwide">
                  <img
                    className="radius w-100"
                    src={data.image}
                    alt={data.title}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* End Image Area  */}
        </div>
        <div className="row mt--40 row--30 rn-section-gap">
          {/* Start Left column  */}
          <div className="col-lg-6">
            <div className="content-left">
              <h4 className="title">{data.title}</h4>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
            </div>
          </div>
          {/* End Left column  */}
          {/* Start Right column  */}
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <div className="content-right">
              <div className="single-list-wrapper">
                <div className="single-list">
                  <label>Length of Engagement:</label>
                  <span> {data.engagement}</span>
                </div>
                <div className="single-list">
                  <label> Support Needed: </label>
                  <span> {data.support}</span>
                </div>
                <div className="single-list">
                  <label> Audience: </label>
                  <span> {data.audience}</span>
                </div>
                <div className="single-list">
                  <label> Requirements: </label>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.requirements,
                    }}
                  ></span>
                </div>
                <div className="single-list">
                  <label> Advantages: </label>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.advantages,
                    }}
                  ></span>
                </div>
                <div className="single-list">
                  <label> White Papers: </label>
                  <span>
                    <div className="view-btn mt--50">
                      <a
                        className="btn-default btn-large round"
                        href={`/${data.whitepaper[0].link}.pdf`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data.whitepaper[0].title}
                      </a>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Start Right column  */}
        </div>
      </div>
      <SectionTitle
        textAlign="text-center"
        radiusRounded=""
        title="Getting Started Step-by-Step"
      />
      <Timeline data={data.Timeline} />
      <div className="container rn-section-gap">
        <PricingThree />
      </div>
    </div>
  );
};

export default HowToDetailsContent;
