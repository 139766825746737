import React from 'react';
import ProductForm from "./ProductForm";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
		                    <div className="col-lg-4 col-md-4 col-12">
								<img className="radius-small" src='./images/banner/big_q.png' alt="Microlearing Lifeskills Traning" />
                                    <div className="inner">
                                        <h4 className="title">Determine how you can:</h4>
                                        <ul>
											<li>Quickly engage both participants and staff in life-skills training</li>
											<li>Deliver consistent and effective development to a wide variety of learners</li>
											<li>Show person-centered outcomes with real time reporting</li>
										</ul>
                                    </div>
                         
                               
                            </div>
                            <div className="col-lg-8 col-md-8 col-12">
								<ProductForm formStyle="contact-form-1" />
                                
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactOne;