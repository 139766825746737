import React , {useState} from 'react';
import Select, { } from 'react-select'
import emailjs from 'emailjs-com';
import ReactGA from 'react-ga';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. We can't wait to get started.</p>
    )
}
const options = [
  { value: 'Workforce Readiness', label: 'Workforce Readiness' },
  { value: 'Independent Living', label: 'Independent Living' },
  { value: 'Wellness', label: 'Wellness' },{ value: 'Create My Own', label: 'Create My Own' },
]
const sizeOptions = [
  { value: '1-50', label: '1 - 50 Participants' },
  { value: '50-100', label: '50-100 Participants' },
  { value: '+100', label: 'More than 100 Participants' }
]
const customStyles = {
    control: (provided) => ({
        ...provided,
        border: 'hsla(0, 0%, 100%, 0.1) 2px solid;',
        borderRadius:'8px',
        background:'transparent'
      }),
    placeholder: (provided) => ({
        ...provided,
        color: 'hsla(0, 0#EDF1F7%, 100%, 0.1) 2px solid;',
    }),
    multiValue: (provided) => ({
        ...provided,
        background: '#f7a08d',
        borderRadius:'8px',
    }),
    menu: (provided) => ({
        ...provided,
        background: '#EDF1F7',
        borderRadius:'8px',
    }),
    option: (provided) => ({
        ...provided,
        background: '#EDF1F7',
        color:'#293241'
    }),
    
  }
function ProductForm({props , formStyle}) {
    const [ result,showresult ] = useState(false);
	ReactGA.event({
  		category: 'User',
  		action: 'Product Sign Up'
	});
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_odnfkdl', 
            'product_form', 
            e.target, 
            'user_RqvV5zTUFsk1xvMOsmy2X'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
             console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="rn-address">
             <h4 className="title">What size is your educational program?</h4>
             <Select
				name="orgSize"
                styles={customStyles} 
				options={sizeOptions}
                required
			/>
			</div>
<div className="rn-address">
             <h4 className="title">What products are you interested in?</h4>
             <Select
				name="products"
                styles={customStyles} 
				options={options}
                required
				isMulti
			/>
			</div>
 
 <div className="rn-address">
 <h4 className="title">Contact information</h4>
			<div className="form-group">
                <input 
                type="text"
                name="fullname"
                placeholder="Your Name"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder="Email Address"
                required
                />
            </div>
			
			<div className="form-group">
            </div>
            <div className="form-group">
                <input 
                type="text"
                name="Organization"
                placeholder="Organization"
                required
                />
            </div>
            <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Subject"
                required
                />
            </div>

            <div className="form-group">
                <textarea 
                name="message"
                placeholder="Your Message"
                required
                >
                </textarea>
            </div>
</div>
            <div className="form-group">
                <button className="btn-default btn-large">Submit Now</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ProductForm;
