import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { FiFeather, FiFilm, FiUsers } from "react-icons/fi";

const TimelineData = [
  {
    id: "1",
    date: "June-July",
    title: "Identifying Partners & Developing Content",
    description:
      "LearningQ is reaching out to a network of Agencies and Instructors to identify participants to use and engage with our microlearning curricula on Healthy Cooking Basics and Workforce Basics. While this outreach is going on, we are busy working with partners to develop additional content for this grant.",
    icon: <FiFilm />,
  },
  {
    id: "2",
    date: "July-August",
    title: "Onboarding Instructors and Learners",
    description:
      "In August, instructors will be given a training session on how to use the LearningQ platform.After this session, instructors will provide your students' emails or phone numbers so they can receive a registration link. Once they log in, the participants will be given a pre-assessment pulled from the quizzes at the end of each section.",
    icon: <FiUsers />,
  },
  {
    id: "3",
    date: "September-October",
    title: "Engagement with Content",
    description:
      "Instructors will assign learners the content for supplemental classroom instruction - either before or after classroom. The learners will watch the videos and take the brief quizzes at the end to show proof of knowledge. Once completed, the instructors will be able to see the results from the quizzes to identify if they need to revisit any learning topics. ",
    icon: <FiUsers />,
  },
  {
    id: "4",
    date: "September-October",
    title: "Feedback and Data Collection",
    description:
      "In October, program partiicpants will be given a brief survey to provide feedback on their experience on the LearningQ platform. Further, there will be a short assessment given to the learners pulled from the quizzes to understand retention of knowledge.  Furthermore, the LearningQ Principal Investigator and Project Manager will pull data from the platform. Data will be collected anonymously and we will look at drop-out rates on different modules, how often learners re-visit a particular session, and how many times learners attempt each quiz.",
    icon: <FiUsers />,
  },
  {
    id: "5",
    date: "November",
    title: "Reporting Findings",
    description:
      "The LearningQ team will complete their data collection and report their findings to NIDILLR and SBIR. All participant in this research program will maintain access to LearningQ until August, 2024. ",
    icon: <FiFeather />,
  },
];

const TimelineGrantStudy = () => {
  return (
    <div className="rn-timeline-wrapper timeline-style-one position-relative">
      <div className="timeline-line"></div>
      {/* Start Single Timeline  */}

      {TimelineData.map((data, index) => (
        <div className="single-timeline mt--50" key={index}>
          <div className="timeline-dot">
            <div className="time-line-circle"></div>
          </div>
          <div className="single-content">
            <div className="inner">
              <div className="row row--30 align-items-center">
                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                  <div className="content">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <span className="date-of-timeline">{data.date}</span>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <h2 className="title">{data.title}</h2>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <p className="description">{data.description}</p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="order-1 order-lg-2 col-lg-6">
                  <div className="icon">{data.icon}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* End Single Timeline  */}
    </div>
  );
};

export default TimelineGrantStudy;
