import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabOne = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                    <img className="radius-small" src='./images/banner/big_q.png' alt="Microlearing Lifeskills Traning" />
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Learners</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Instructors</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Organizations</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                        <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>The LearningQ approach to life skills training allows learners the flexibility to engage and reinforce concepts at their unique level. The short skill units break complex concepts down into manageable sessions and then immediately check for understanding.</p>
		                                                <p>By their short nature skill units become powerful education tools that can be brought along and accessed anywhere to reinforce skill comprehension and retention.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Instructors gain ease of use and flexibily by being able to quickly create a daily lesson plan of any length. The realtime feedback on skills development for both in-person and remote learning helps to guide instruction and maintain engagement.</p>
		                                                <p>Our badging system helps provide lesson building guidance for more complex training, and the skill based certifications create a way to maintain high quaility standards while providing the flexibily for instructors to work with individual learners at their own pace.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Using LearningQ maintains high quality learning and personal engagement throughout an organization even if learners need to work with multiple staff members. The quick onboarding process for both learners and staff lets organizations provide consistent services during periods of transition.</p>
		                                                <p>Our real time reporting provides transparency and uniform quality for all stakeholders. Learning path skills recommendations based on learner input ensure a person-centered approach.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabOne
