import React from 'react';
import { FiCheck } from "react-icons/fi";

const AboutFour = ({image}) => {
    return (
<div className="about-area about-style-4">
  <div className="container">
    <div className="row row--40 align-items-center">
      <div className="col-lg-5">
      <img
                src="../../images/about/police.jpg"
                alt="IDD Safe Officer"
                style={{ 
                    borderRadius: "50px"
                }}
              />
       
      </div>
      <div className="col-lg-7 mt_md--40 mt_sm--40">
        <div className="content">
          <div className="inner">
            
            <ul className="feature-list">
              <li>
                <div className="icon">
                  <FiCheck />
                </div>
                <div className="title-wrapper">
                  <h4 className="title">Expert-Crafted Curriculum</h4>
                  <p className="text">
                    Developed through the collaboration of both veteran police officers and IDD professionals, this program addresses the real challenges individuals face when interacting with the police, drawing on multiple perspectives and years of experience.
                  </p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <FiCheck />
                </div>
                <div className="title-wrapper">
                  <h4 className="title">Interactive and Engaging</h4>
                  <p className="text">
                    Our lessons are interactive, featuring real-life scenarios and techniques that are practical and easy to remember.
                  </p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <FiCheck />
                </div>
                <div className="title-wrapper">
                  <h4 className="title">Flexible Learning Options</h4>
                  <p className="text">
                    We offer a variety of learning formats—self-guided courses, live virtual sessions, and organizational support—allowing learners and facilitators to choose the best way to engage with the material, ensuring accessibility and convenience for everyone.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    )
}

export default AboutFour
