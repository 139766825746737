import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";

const Data = [
    {
        countNum : 3303,
        countTitle: 'Skill Unit Combinations',
        description: 'each skill unit is written for active practice versus passive learning',
    },
    {
        countNum : 5,
        countTitle: 'Minutes to Onboard',
        description: 'we make everything simple and easy to use',
    },
    {
        countNum : 1,
        countTitle: 'Your LearningQ',
        description: 'uniquely supports your learning interests with new suggestions and supports',
    },
];
const CounterUpTwo = ({textALign, counterStyle, column}) => {
    return (
        <div className="row">
            {Data.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible && 
                                <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                        </TrackVisibility>
                        <h5 className="title">{data.countTitle}</h5>
                        <p className="description">{data.description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default CounterUpTwo;