import React from "react";
import PortfolioDetailsContent from "../../elements/portfolio/PortfolioDetailsContent";
import ClassesData from "../../data/portfolio/ClassesData.json";
import SEO from "../../common/SEO";
import HeaderGrant from "../../common/header/HeaderGrant";
import FooterFour from "../../common/footer/FooterThreeAlt";

const ClassDetails = ({
  match: {
    params: { id },
  },
}) => {
  const portfolioId = parseInt(id, 10);
  const data = ClassesData.filter((portfolio) => portfolio.id === portfolioId);
  return (
    <>
      <SEO title={`Class: ${data[0].category}`} />
      <main className="page-wrapper">
        <HeaderGrant
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />
        <PortfolioDetailsContent data={data[0]} />
        <FooterFour />
      </main>
    </>
  );
};

export default ClassDetails;
