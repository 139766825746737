import React from "react";
import TrackVisibility from "react-on-screen";

import { Link } from "react-router-dom";

const SlipGrantContent = () => {
  return (
    <div className="rn-splite-style bg-color-blackest">
      <div className="split-wrapper">
        <div className="row row--30 align-items-center ">
          <div
            className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12 height-650 bg_image "
            data-black-overlay="5"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/split/workforcebasics.jpg)`,
              backgroundAttachment: "local",
              backgroundSize: "cover",
            }}
          >
            <div
              className="split-inner text-center"
              style={{ margin: "0 auto" }}
            >
              <h2 className="title" style={{ color: "#EE6C4D" }}>
                Workforce Basics
              </h2>{" "}
              <h5 style={{ color: "#FFF" }}>
                Looking for the best content on Workforce Training?
              </h5>
              <div className="content text-center">
                <TrackVisibility once>
                  <Link
                    className={`btn-default`}
                    style={{ margin: "0 auto" }}
                    to="/grant/workforce"
                  >
                    Find Out More!
                  </Link>
                </TrackVisibility>
              </div>
            </div>
          </div>
          <div
            className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12 height-650 bg_image"
            data-white-overlay="5"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/split/healthycooking.jpg)`,
              backgroundAttachment: "local",
              backgroundSize: "cover",
            }}
          >
            <div
              className="split-inner text-center"
              style={{ margin: "0 auto" }}
            >
              <h2 className="title" style={{ color: "#EE6C4D" }}>
                Healthy Cooking Basics
              </h2>{" "}
              <h5>
                Want Effective Instruction on Cooking, Kitchen Basics and
                Safety?
              </h5>
              <div className="content text-center">
                <TrackVisibility once>
                  <Link
                    className={`btn-default`}
                    style={{ margin: "0 auto" }}
                    to="/grant/healthycooking"
                  >
                    Find Out More!
                  </Link>
                </TrackVisibility>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SlipGrantContent;
