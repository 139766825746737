import React from "react";
import ReactPlayer from "react-player";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../../common/SEO";
import HeaderGrant from "../../common/header/HeaderGrant";
import FooterFour from "../../common/footer/FooterThreeAlt";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import TimelineGrantPartner from "../../elements/timeline/TimelineGrantPartner";
import AccordionGrantPartner from "../../elements/accordion/AccordionGrantPartner";
import CalltoActionContent from "../../elements/calltoaction/CalltoActionContent";
import Split from "../../elements/split/SlipGrantContent";
import Modal from "../../elements/modal/Modal";
import SignUpForm from "../../elements/forms/GrantSignUp";
import { useState } from "react";
import { Link } from "react-router-dom";

class ResponsivePlayer extends ReactPlayer {
  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/845385102"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    );
  }
}

const Agency = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  function showModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }
  return (
    <>
      <SEO title="LearningQ | Agency Grant Partner Information" />
      <main className="page-wrapper">
        <HeaderGrant btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Slider Area  */}
        <div>
          <div
            className="container bg_image bg_image_fixed"
            data-black-overlay="4"
            style={{
              maxWidth: "100%",
              padding: "0",
              margin: "0",
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
            }}
          >
            <div className="row row--30 align-items-center">
              <div className="order-1 order-lg-1 col-lg-8">
                <div className="thumbnail">
                  <ResponsivePlayer />
                </div>
              </div>
              <div className="order-2 order-lg-2 col-lg-4 mt_md--50 mt_sm--50 mt_lg--30">
                <div
                  className="inner text-left"
                  style={{ padding: "60px 20px 20px 20px" }}
                >
                  <h1
                    className="title theme-gradient"
                    style={{ fontSize: "50px" }}
                  >
                    Free Access to Two All New Certifications for a Year
                  </h1>
                  <p
                    className="description"
                    style={{ fontSize: "15px", lineHeight: "19px" }}
                  >
                    We are developing two all new certifications with over 60
                    engaging microunits. Each topic was chosen to address high
                    demand areas of need with content that is easy for
                    particpants to absorb at their own pace. Use LearningQ to
                    enhance both live and online programs.
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                    <button className="btn-default" onClick={showModal}>
                      Get Started{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-service-area ">
          <div className="container rn-section-gap">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="We've got a better way to learn"
                  description="We break complex concepts into 2-5 minute, video based skill
                  units unlocking better results for Learners, Instructors,
                  and Organizations."
                />
              </div>
            </div>
            <div className="row text-center" style={{ marginTop: "30px" }}>
              <div className="col-lg-12">
                <a className="btn-default" href="/grantpartners.pdf">
                  Download Partnership Info
                </a>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <Split />
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area  rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="LearningQ Grant Partnerships"
                  description="We are looking for enthusiastic agencies and instructors to leverage LearningQ's platform and content with your existing in-person or virtual programming. There are several ways to incorporate LearningQ into your current classrooms."
                />
              </div>
            </div>
            <TimelineGrantPartner textAlign="text-center" />
            <div className="col-12 align-items-center rn-section-gap">
              <Link
                style={{ margin: "0 auto" }}
                className="btn-default"
                to="/grant/Study"
              >
                Read Program Specifics
              </Link>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <div className="rn-about-area about-style-3 rn-section-gap">
          <div className="container">
            <div className="row row--30">
              <div className="col-lg-11 mt_md--40 mt_sm--40">
                <div className="inner">
                  <div className="section-title">
                    <h2 className="title mb--40">Frequent Questions</h2>
                  </div>
                  <AccordionGrantPartner customStyle="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <CalltoActionContent />
        <FooterFour />
      </main>
      <Modal isOpen={isModalOpen} title="Grant Sign Up" onClose={closeModal}>
        <SignUpForm />
      </Modal>
    </>
  );
};

export default Agency;
