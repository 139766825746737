import React from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderThree";
import FooterFour from "../common/footer/FooterThreeAlt";
import Separator from "../elements/separator/Separator";




const rawHTML = `<p><b>Last updated: January 2023</b></p>

<h1>Welcome; Intro</h1>
<p>
    Welcome, and thank you for your interest in LearningQ, llc, is an
    organization ("LearningQ, llc," "we," "us," or "our"), which operates the
    web site located at <a
        href="https://www.learningq.org/"
        target="_blank"
        rel="noreferrer">www.learningq.org</a
    >
    and other websites (collectively, the “Website”) and related application programming
    interfaces (“APIs”), mobile applications and online services, any Downloadable
    Content (as defined below), and any other products and services that Company
    may provide now or in the future (collectively, the “Services”).  The following
    Terms of Service are a legal contract between you (“you” and “your”) and LearningQ,
    llc regarding your use of the Services.  Visitors and users of the Services are
    referred to individually as “User” and collectively as “Users”.  Use of the Services
    is governed by these Terms of Service and our
    <a href="/policies/privacy" class="link">LearningQ, llc Privacy Policy</a> (as
    applicable to which Services you choose to use), where the Privacy Policy describes
    the personal information that we collect and how we use and share it.
</p>
<p>
    PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY. BY REGISTERING FOR,
    ACCESSING, BROWSING, OR USING THE WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE
    READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING TERMS AND
    CONDITIONS, AND ANY ADDITIONAL GUIDELINES (AS DEFINED BELOW) (COLLECTIVELY, 
    THE “TERMS”), AND THAT YOU HAVE READ THE <a
        href="/policies/privacy"
        class="link">LEARNINGQ, LLC PRIVACY POLICY</a
    >.
</p>
<p>
    PLEASE NOTE THAT THESE TERMS INCLUDE A BINDING ARBITRATION PROVISION,
    INCLUDING A CLASS ACTION WAIVER. BY AGREEING TO BINDING ARBITRATION, TO THE
    EXTENT PERMITTED UNDER APPLICABLE LAW, YOU WAIVE YOUR RIGHT TO LITIGATE
    DISPUTES THROUGH A COURT AND TO HAVE A JUDGE OR JURY DECIDE YOUR CASE.
</p>

<h1>1. Eligibility; Accounts</h1>
<p>
    THE WEBSITE IS NOT AVAILABLE TO: (A) ANY USERS PREVIOUSLY SUSPENDED OR
    REMOVED FROM THE WEBSITE BY LEARNINGQ, LLC, OR (B) ANY PERSONS UNDER THE AGE
    OF 13 WHOSE REGISTRATION HAS NOT BEEN APPROVED BY A LEGAL PARENT OR
    GUARDIAN.
</p>

<p><b>1.1. Acceptance</b></p>
<p>
    By using or registering an account for the Services, you represent that (i)
    you are a US resident at least 13 years of age, or (ii) you are not a US
    resident, and are of legal age of consent to open an account under the laws
    of your country of residence, or (iii) your use of the Services has been
    approved by your parent or legal guardian, or by your school or teacher for
    school use.  You also represent that you have not been previously suspended
    or removed from the Services by LearningQ, llc, and that your registration
    and your use of the Services is in compliance with any and all applicable
    laws.
</p>

<p><b>1.2. Account</b></p>
<p>
    In order to use certain features of the Services, you must register for an
    account. You may be asked to provide a password in connection with your
    account.  You are solely responsible for maintaining the confidentiality of
    your account and password, and you agree to accept responsibility for all
    activities that occur under your account or password.  You agree that the
    information you provide to LearningQ, llc, whether at registration or at any
    other time, will be true, accurate, current, and complete.  You also agree
    that you will ensure that this information is kept accurate and up-to-date
    at all times.  If you have reason to believe that your account is no longer
    secure (e.g., in the event of a loss, theft, or unauthorized disclosure or
    use of your account ID or password), then you agree to immediately notify
    LearningQ, llc at <a href="mailto:info@learningq.org">info@learningq.org</a
    >.  You may be liable for the losses incurred by LearningQ, llc or others
    due to any unauthorized use of your Services account.
</p>

<p><b>1.3. Integrated Service</b></p>
<p>
    LearningQ, llc may permit you to register for the Services through, or
    otherwise associate your LearningQ, llc account with, certain third-party
    social networking or integrated services, such as Google (“Integrated
    Service”).  By registering for the Services using (or otherwise granting
    access to) an Integrated Service, you agree that LearningQ, llc may access
    your Integrated Service’s account information, and may store and use certain
    information already associated with the Integrated Service consistent with
    our <a href="/policies/privacy" class="link">Privacy Policy</a>, and you
    agree to any and all terms and conditions of the Integrated Service
    regarding your use of the Services via the Integrated Service. You may
    revoke LearningQ, llc's access to your account on any Integrated Service at
    any time by updating the appropriate account settings of the respective
    Integrated Service. You should check your privacy settings on each
    Integration Service to understand and change the information sent to us
    through each Integration Service. You agree that any Integrated Service is a
    Reference Site (as defined below) and you are solely responsible for your
    interactions with the Integrated Service as a result of accessing the
    Services through the Integrated Service.  LearningQ, llc does not control
    the practices of Integrated Services, and you are advised to read the
    <a href="/policies/privacy" class="link">Privacy Policy</a> and terms and
    conditions of any Integrated Service that you use to understand their
    practices.
</p>

<p><b>1.4. Coaches</b></p>
<p>
    LearningQ, llc may make available certain features and tools that allow
    Users to add "Coaches" to their account.
</p>

<p>
    1.4.1. Coaching features are provided to allow any User to give another
    User, such as a tutor, friend or family member, the ability to view the
    activity in their LearningQ, llc account in order to provide them with
    tutoring or coaching help on LearningQ, llc. In these Terms, we refer to any
    User that you connect to your LearningQ, llc account as a <b>“Coach.”</b>
</p>

<p>
    1.4.2. If you are a Coach, the following provisions apply to you.  You may
    use LearningQ, llc features and tools only for purposes of providing
    tutoring, coaching or other education-related assistance to a User that has
    an account established and existing pursuant to these Terms. You must use
    the Services and the Website in compliance with all applicable laws, rules,
    and regulations.
</p>

<p><b>1.5. International Use</b></p>
<p>
    LearningQ, llc operates the Services in the United States.  If you choose to
    access our Services from locations outside the United States, you consent to
    the collection, transmission, use, storage and processing of content and
    data (including your personal information) in the United States.  You agree
    to comply with and are solely responsible for ensuring compliance with all
    local laws, regulations, and rules in the jurisdiction in which you reside
    or access the Services, if and to the extent local laws are applicable to
    use of our Services.  If you are under the age of 18 (or the age of majority
    in the jurisdiction in which you reside), you confirm that you have received
    parental consent, if required in your jurisdiction, to open an account for
    Service and enter into these Terms.  Unless we have entered into a separate,
    mutually executed written agreement with you that says otherwise, we do not
    represent that our Services are appropriate or available for use in
    jurisdictions outside the United States. The right to access and use the
    Services is not granted in jurisdictions, if any, where it may be
    prohibited, or where your use would render LearningQ, llc in violation of
    any applicable laws or regulations, including without limitation, Applicable
    Privacy Laws.
</p>

<h1>2. Privacy Policy</h1>
<p>
    Your privacy is important to LearningQ, llc.  Please read the <a
        href="/policies/privacy"
        class="link">LearningQ, llc Privacy Policy</a
    >
    and  LearningQ, llc's collection, use, and disclosure of your personal information. 
    Among other things, our
    <a href="/policies/privacy" class="link">LearningQ, llc Privacy Policy</a
    > explains how we treat your personal information and protect your privacy
    when you use our Services.
</p>

<h1>3. Other Guidelines</h1>
<p>
    When using the Services, you will be subject to any additional posted
    guidelines or rules applicable to specific services and features which may
    be posted from time to time (the <b>“Guidelines”</b>).  All such Guidelines
    are hereby incorporated by reference into the Terms.
</p>

<h1>4. Modification of the Terms</h1>
<p><b>4.1. General</b></p>
<p>
    Upon opening an account, you accept the Terms in the form posted on our
    website.  LearningQ, llc reserves the right, at our discretion, to change,
    modify, add, or remove portions of the Terms at any time.  Please check the
    Terms and any Guidelines periodically for changes that are made after you
    open your account.  Your continued use of the Services after the posting of
    changes constitutes your binding acceptance of such changes.  For any
    material changes to the Terms, LearningQ, llc will make reasonable effort to
    provide notice to you of such amended Terms, such as by an email
    notification to the address associated with your account or by posting a
    notice on the Services, and such amended terms will be effective against you
    on the earlier of (i) your actual notice of such changes and (ii) thirty
    days after LearningQ, llc makes reasonable attempt to provide you such
    notice.  However, changes addressing new functions for a service or changes
    made for legal reasons will be effective immediately.  If you do not agree
    to the modified Terms, you must discontinue your use of the Services.
    Disputes arising under these Terms will be resolved in accordance with the
    version of the Terms in place at the time the dispute arose.
</p>

<h1>5. User Feedback</h1>
<p>
    By submitting suggestions or other feedback related to our Services to us,
    you agree that we can (but do not have to) use and share such feedback for
    any purpose without compensation to you. We will honor any limits we agree
    to at the time we collect feedback.
</p>

<h1>6. Proprietary Materials; Licenses</h1>
<p><b>6.1. Proprietary Materials</b></p>
<p>
    The Services are owned and operated by LearningQ, llc and its content
    partners, known as “we.”  The visual interfaces, graphics, design,
    compilation, information, computer code (including source code or object
    code), software, services, content, educational videos and exercises, and
    all other elements of the Services (the <b>“Services Materials”</b>) are
    protected by United States and international copyright, patent, and
    trademark laws, international conventions, and other applicable laws
    governing intellectual property and proprietary rights. All Services
    Materials, and all trademarks, service marks, and trade names, contained on
    or available through the Services are owned by or licensed to LearningQ,
    llc, and LearningQ, llc reserves all rights therein and thereto not
    expressly granted by these Terms.
</p>

<p><b>6.2. Licensed Educational Content</b></p>
<p>
    LearningQ, llc may make available on the Services certain educational
    videos, exercises, and related supplementary materials that are owned by
    LearningQ, llc or its third-party licensors (the <b
        >“Licensed Educational Content”</b
    >).  LearningQ, llc grants to you a non-exclusive, non-transferable right to
    access and use the Licensed Educational Content as made available on the
    Services by LearningQ, llc solely for your personal, non-commercial
    purposes.  Unless expressly indicated on the Services that a particular item
    of Licensed Educational Content is made available to Users under alternate
    license terms, you may not download, distribute, sell, lease, modify, or
    otherwise provide access to the Licensed Educational Content to any third
    party.
</p>

<p><b>6.3. Non-Commercial Use</b></p>
<p>
    The Licensed Educational Content and Licensed Educational Code are intended
    for personal, non-commercial use only.  Without limiting the foregoing, and
    notwithstanding the terms of any Alternate License for such Licensed
    Educational Content, the Licensed Educational Content may not be used,
    distributed or otherwise exploited for any commercial purpose, commercial
    advantage or private monetary compensation, unless otherwise previously
    agreed in writing by LearningQ, llc.
</p>

<p>
    6.3.1. Impermissible Uses.  Without limiting the generality of the
    foregoing, the following are types of uses that LearningQ, llc expressly
    defines as falling outside of “non-commercial” use:
</p>

<p class="indent-1">
    6.3.1.1. the sale or rental of (1) any part of the Licensed Educational
    Content, (2) any derivative works based at least in part on the Licensed
    Educational Content, or (3) any collective work that includes any part of
    the Licensed Educational Content;
</p>

<p class="indent-1">
    6.3.1.2. providing training, support, or editorial services that use or
    reference the Licensed Educational Content in exchange for a fee; and
</p>

<p class="indent-1">
    6.3.1.3. the sale of advertisements, sponsorships, or promotions placed on
    the Licensed Educational Content, or any part thereof, or the sale of
    advertisements, sponsorships, or promotions on any website or blog
    containing any part of the Licensed Educational Material, including without
    limitation any “pop-up advertisements”.
</p>

<p><b>6.4. Crediting LearningQ, llc</b></p>
<p>
    If you distribute, publicly perform or display, transmit, publish, or
    otherwise make available any Licensed Educational Content or any derivative
    works thereof, you must also provide the following notice prominently along
    with such Licensed Educational Content or derivative work thereof.
</p>

<h1>7. Proprietary Materials; Licenses</h1>
<p>YOU AGREE NOT TO:</p>
<p class="indent-1">
    7.1. use the Services for any commercial use or purpose unless expressly
    permitted by LearningQ, llc in writing, it being understood that the
    Services and related services are intended for personal, non-commercial use
    only;
</p>
<p class="indent-1">
    7.2. except as expressly permitted under Sections 5.3 (License Grant to
    Users) and 6 (Proprietary Materials; Licenses) of these Terms, rent, lease,
    loan, sell, resell, sublicense, distribute or otherwise transfer the
    licenses for any Services Materials;
</p>
<p class="indent-1">
    7.3. post, upload, or distribute any defamatory, libelous, or inaccurate
    content;
</p>
<p class="indent-1">
    7.4. impersonate any person or entity, falsely claim an affiliation with any
    person or entity, or access the Services accounts of others without
    permission, create accounts via bots or other automated means, or perform
    any other fraudulent activity;
</p>
<p class="indent-1">
    7.5. develop, support or use software, devices, scripts, robots, or any
    other means or processes (including crawlers, browser plugins and add-ons,
    or any other technology) to scrape the Services or otherwise copy lessons
    and other data from the Services;
</p>
<p class="indent-1">
    7.6. Use bots or other automated methods to access the Services;
</p>
<p class="indent-1">
    7.7. delete (or otherwise obscure or alter) the copyright or other
    proprietary rights notices on the Services or on any Licensed Educational
    Content and Licensed Educational Code;
</p>
<p class="indent-1">
    7.8. assert, or authorize, assist, or encourage any third party to assert,
    against LearningQ, llc or any of its affiliates or licensors any patent
    infringement or other intellectual property infringement claim regarding any
    Licensed Educational Content and Licensed Educational Code you have used,
    submitted, or otherwise made available on or through the Services;
</p>
<p class="indent-1">
    7.9. make unsolicited offers, advertisements, proposals, or send junk mail
    or spam to other Users of the Services (including, but not limited to,
    unsolicited advertising, promotional materials, or other solicitation
    material, bulk mailing of commercial advertising, chain mail, informational
    announcements, charity requests, and petitions for signatures);
</p>
<p class="indent-1">
    7.10. use the Services for any illegal purpose, or in violation of any
    local, state, national, or international law, including, without limitation,
    laws governing intellectual property and other proprietary rights, and data
    protection and privacy;
</p>
<p class="indent-1">
    7.11. defame, harass, bully, abuse, threaten or defraud Users of the
    Services, or collect, or attempt to collect, personal information about
    Users or third parties without their consent;
</p>
<p class="indent-1">
    7.12. probe, scan, remove, circumvent, disable, damage or otherwise
    interfere with or test the vulnerability of security-related features of the
    Services, Licensed Educational Content, Licensed Educational Code, features
    that prevent or restrict use or copying of any content accessible through
    the Services, or features that enforce limitations on the use of the
    Services, Licensed Educational Content, Licensed Educational Code, or
    otherwise access, tamper with, or use non-public portions of the Services
    without our authorization;
</p>
<p class="indent-1">
    7.13. reverse engineer, decompile, disassemble or otherwise attempt to
    discover the source code of the Services or any part thereof, except and
    only to the extent that such activity is expressly permitted by applicable
    law (in which case you must contact LearningQ at info@learningq.org to give
    notice of the proposed activity and discuss alternative means to obtain the
    desired information from LearningQ, llc) notwithstanding this limitation;
</p>
<p class="indent-1">
    7.14. modify, adapt, translate or create derivative works based upon the
    Services or any part thereof, except and only to the extent expressly
    permitted by LearningQ, llc herein or to the extent the foregoing
    restriction is expressly prohibited by applicable law (in which case you
    must contact us at info@learningq.org to give notice of the proposed
    activity and discuss whether LearningQ, llc is willing to provide the
    desired derivative works); or
</p>
<p class="indent-1">
    7.15. intentionally interfere with or damage operation of the Services or
    any user's enjoyment of it, by any means, including without limitation by
    participation in any denial-of-service type attacks or by uploading or
    otherwise disseminating viruses, adware, spyware, worms, or other malicious
    code.
</p>

<h1>8. Third-Party Sites, Products and Services; Links</h1>
<p>
    The Services may include links or references to other web sites or services
    solely as a convenience to Users (“<b>Reference Sites</b>”). LearningQ, llc
    does not endorse any such Reference Sites or the information, materials,
    products, or services contained on or accessible through Reference Sites. 
    ACCESS AND USE OF REFERENCE SITES, INCLUDING THE INFORMATION, MATERIALS,
    PRODUCTS, AND SERVICES ON OR AVAILABLE THROUGH REFERENCE SITES, IS SOLELY AT
    YOUR OWN RISK.
</p>

<h1>9. Third-Party Sites, Products and Services; Links</h1>
<p><b>9.1. Term</b></p>
<p>
    These Terms shall remain in full force and effect while you use the Services
    unless your account is terminated as provided in these Terms, in which case
    you no longer have the right to use the Services.
</p>

<p><b>9.2. Termination by LearningQ, llc</b></p>
<p>
    LearningQ, llc, in its sole discretion, for any or no reason, and without
    penalty, may: (a) restrict, suspend or terminate: (i) any account (or any
    part thereof) you may have with LearningQ, llc or (ii) your use of the
    Services, and (b) remove and discard all or any part of your account, User
    profile, and User Content, at any time.  LearningQ, llc may also in its sole
    discretion and at any time discontinue providing access to the Services, or
    any part thereof, with or without notice.  You agree that any termination of
    your access to the Services or any account you may have, or portion thereof,
    may be affected without prior notice, and you agree that LearningQ, llc will
    not be liable to you or any third party for any such termination. Any
    suspected fraudulent, abusive or illegal activity may be referred to
    appropriate law enforcement authorities. These remedies are in addition to
    any other remedies LearningQ, llc may have at law or in equity. As discussed
    herein, LearningQ, llc does not permit copyright, trademarks, or other
    intellectual property infringing activities on the Services, and will
    terminate access to the Services, and remove all User Content or other
    content submitted, by any Users who are found to be repeat infringers.
</p>

<p><b>9.3. Termination by You</b></p>
<p>
    Your only remedy with respect to any dissatisfaction with (i) the Services,
    (ii) any term of these Terms of Service, (iii) Guidelines, (iv) any policy
    or practice of LearningQ, llc in operating the Services, or (v) any content
    or information transmitted through the Services, is to terminate the Terms
    and your account.  You may terminate these Terms at any time (prospectively
    only) by deleting your login account with the Services and discontinuing use
    of any and all parts of the Services.
</p>

<p><b>9.4. Responsibility for Pre-Termination activity</b></p>
<p>
    Termination of the Terms as to any User account will not limit LearningQ,
    llc's rights and remedies regarding any breach of these Terms occurring
    prior to such termination.
</p>

<h1>10. Representations and Warranties</h1>
<p>
    You warrant, represent and agree that you will not provide any User Content
    or otherwise use the Services in a manner that (i) infringes, violates or
    misappropriates another's intellectual property rights, rights of publicity
    or privacy, or other rights; (ii) violates any international, federal, state
    or local law, statute, ordinance or regulation or which would render
    LearningQ, llc in violation of any applicable laws or regulations, including
    without limitation, Applicable Privacy Laws (collectively, <b
        >"Applicable Law"</b
    >); (iii) is harmful, fraudulent, threatening, abusive, harassing, tortuous,
    defamatory, vulgar, obscene, libelous, or otherwise objectionable; or (iv)
    jeopardizes the security of your account or the Services in any way, such as
    allowing someone else access to your account or password or submitting User
    Content that contains viruses.
</p>

<h1>11. Indemnification</h1>
<p>
    You agree, to the extent permissible under your state's laws, to indemnify,
    defend, and hold harmless LearningQ, llc, and its parent, successors,
    affiliated companies, contractors, officers, directors, employees, agents
    and its third-party suppliers, licensors, and partners (“<b
        >LearningQ, llc Parties</b
    >") from and against all losses, damages, liabilities, demands, judgments,
    settlements, costs and expenses of any kind (including legal fees and
    expenses), from any claim or demand made by any third-party relating to or
    arising out of (i) your access to, use or misuse of the Services; (ii) your
    breach or alleged breach of these Terms, or any violation of the Terms;
    (iii) any breach of the representations, warranties, and covenants made
    herein, (iv) your failure to comply with Applicable Laws(including any
    failure to obtain or provide any necessary consent or notice); (v) the
    infringement by you or any third-party using your account of any
    intellectual property, privacy, or other right of any person or entity,
    including in connection with your User Content, or (vi) your breach or
    alleged breach of any interaction, agreement, or policy between you and any
    other Users. LearningQ, llc reserves the right, at your expense, to assume
    the exclusive defense and control of any matter for which you are required
    to indemnify LearningQ, llc, and you agree to cooperate with LearningQ,
    llc's defense of these claims.  You agree not to settle any such matter
    without the prior written consent of LearningQ, llc. LearningQ, llc will use
    reasonable efforts to notify you of any such claim, action, or proceeding
    upon becoming aware of it.
</p>

<h1>12. Disclaimers; No Warranties</h1>
<p><b>12.1. No Warranties</b></p>
<p>
    THE WEBSITE, AND ALL DATA, INFORMATION, SOFTWARE, WEBSITE MATERIALS, CONTENT
    (WHETHER OWNED OR LICENSED), USER CONTENT, REFERENCE SITES, SERVICES, OR
    APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE WEBSITE (THE
    "LEARNINGQ, LLC OFFERINGS"), ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE,” AND
    “WITH ALL FAULTS” BASIS. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
    APPLICABLE LAW, THE LEARNINGQ, LLC PARTIES DISCLAIM ANY AND ALL WARRANTIES
    AND CONDITIONS OF ANY KIND, WHETHER STATUTORY, EXPRESS OR IMPLIED,
    INCLUDING, BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
    QUALITY, AVAILABILITY, QUIET ENJOYMENT, FITNESS FOR A PARTICULAR PURPOSE,
    TITLE, AND NON-INFRINGEMENT.  NO ADVICE OR INFORMATION, WHETHER ORAL OR
    WRITTEN, OBTAINED BY YOU FROM LEARNINGQ, LLC OR THROUGH THE WEBSITE WILL
    CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.
</p>

<p><b>12.2. Content</b></p>
<p>
    LEARNINGQ, LLC, AND THE LEARNINGQ, LLC PARTIES, DO NOT WARRANT THAT THE
    WEBSITE OR ANY DATA, USER CONTENT, FUNCTIONS, OR ANY OTHER INFORMATION
    OFFERED ON OR THROUGH THE WEBSITE WILL BE UNINTERRUPTED, OR FREE OF ERRORS,
    VIRUSES OR OTHER HARMFUL COMPONENTS, AND DO NOT WARRANT THAT ANY OF THE
    FOREGOING WILL BE CORRECTED.
</p>
<p>
    LEARNINGQ, LLC AND THE LEARNINGQ, LLC PARTIES MAKE NO REPRESENTATION OR
    WARRANTY THAT (1) THE LEARNINGQ, LLC OFFERINGS WILL (A) MEET YOUR
    REQUIREMENTS OR EXPECTATIONS, OR BE TO YOUR LIKING, OR (B) WILL BE TIMELY,
    SECURE, ACCURATE, FREE FROM ERRORS OR LOSS, OR UNINTERRUPTED, (2) THAT THE
    SERVICES ARE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS, OR (3) THAT ANY
    DEFECTS OR ERRORS WILL BE CORRECTED. SOME FEATURES MAY BE NEW OR
    EXPERIMENTAL AND MAY NOT HAVE BEEN TESTED IN ANY MANNER.
</p>

<p><b>12.3. Harm to Your Computer </b></p>
<p>
    YOU UNDERSTAND AND AGREE THAT YOUR USE, ACCESS, DOWNLOAD, OR OTHERWISE
    OBTAINING OF CONTENT, WEBSITE MATERIALS, SOFTWARE, OR DATA THROUGH THE
    WEBSITE (INCLUDING THROUGH ANY API'S) IS AT YOUR OWN DISCRETION AND RISK,
    AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY
    (INCLUDING YOUR COMPUTER SYSTEM) OR LOSS OF DATA THAT RESULTS THEREFROM.
</p>

<p><b>12.4. Limitations by Applicable Law</b></p>
<p>
    SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
    WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE
    OTHER RIGHTS THAT VARY FROM STATE TO STATE AND JURISDICTION TO JURISDICTION.
</p>

<h1>13. Limitation of Liability and Damages</h1>
<p><b>13.1. Limitation of Liability</b></p>
<p>
    UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, WILL
    LEARNINGQ, LLC OR THE LEARNINGQ, LLC PARTIES BE LIABLE FOR ANY SPECIAL,
    INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, RELIANCE, OR EXEMPLARY
    DAMAGES (INCLUDING WITHOUT LIMITATION DAMAGES ARISING FROM ANY UNSUCCESSFUL
    COURT ACTION OR LEGAL DISPUTE, LOST BUSINESS, LOST REVENUES OR PROFITS, LOSS
    OF DATA, OR ANY OTHER PECUNIARY OR NON-PECUNIARY LOSS OR DAMAGE OF ANY
    NATURE WHATSOEVER) EVEN IF LEARNINGQ, LLC OR A LEARNINGQ, LLC PARTIES HAS
    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES ARISING OUT OF OR RELATING
    (i) TO THE TERMS; (ii) YOUR USE OF (OR INABILITY TO USE) THE WEBSITE OR THE
    LEARNINGQ, LLC OFFERINGS, OR (iii) ANY OTHER INTERACTIONS WITH LEARNINGQ,
    LLC OR ANY THIRD-PARTY THROUGH OR IN CONNECTION WITH THE LEARNINGQ, LLC
    OFFERINGS, INCLUDING OTHER USERS,.  APPLICABLE LAW MAY NOT ALLOW THE
    LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES,
    SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES,
    LEARNINGQ, LLC'S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED
    BY APPLICABLE LAW.
</p>

<p><b>13.2. Limitation of Damages</b></p>
<p>
    IN NO EVENT WILL LEARNINGQ, LLC'S OR THE LEARNINGQ, LLC PARTIES' TOTAL
    LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT
    OF OR RELATING TO THE TERMS OR YOUR USE OF THE WEBSITE OR YOUR INTERACTION
    WITH OTHER WEBSITE USERS (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
    WARRANTY, OR OTHERWISE), EXCEED THE AMOUNT PAID BY YOU TO LEARNINGQ, LLC, IF
    ANY, FOR ACCESSING THE WEBSITE DURING THE TWELVE MONTHS IMMEDIATELY
    PRECEDING THE DATE OF THE CLAIM OR ONE HUNDRED DOLLARS ($100), WHICHEVER IS
    GREATER.
</p>

<p><b>3.3. Basis of the Bargain</b></p>
<p>
    YOU ACKNOWLEDGE AND AGREE THAT LEARNINGQ, LLC HAS OFFERED THE WEBSITE AND
    ENTERED INTO THE TERMS IN RELIANCE UPON THE DISCLAIMERS AND THE LIMITATIONS
    OF LIABILITY SET FORTH HEREIN, THAT THE DISCLAIMERS AND THE LIMITATIONS OF
    LIABILITY SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK
    BETWEEN YOU AND LEARNINGQ, LLC, AND THAT THE DISCLAIMERS AND THE LIMITATIONS
    OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN
    YOU AND LEARNINGQ, LLC.  LEARNINGQ, LLC WOULD NOT BE ABLE TO PROVIDE THE
    WEBSITE TO YOU ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE
    LIMITATIONS.
</p>

<p><b>13.4. User Interactions and Release</b></p>
<p>
    13.4.1. User Disputes. LearningQ, llc is not responsible for the actions,
    content, information or data of other third parties, including other Users.
    You are solely responsible for your interactions with other users of the
    Services, and any other parties with whom you interact through the Service.
    You should make whatever investigation you feel necessary or appropriate
    before proceeding with any online or offline interaction with any other
    person. We reserve the right, but have no obligation, to become involved in
    any way with these disputes.
</p>

<p>
    13.4.2. Release. If you have a dispute with one or more Users, you release
    us (and the LearningQ, llc Parties) from all claims, demands and damages
    (actual and consequential) of every kind and nature, known and unknown,
    arising out of or in any way connected with such disputes, including damages
    for loss of profits, goodwill, use, privacy or data. If you are a California
    resident, you waive your rights under California Civil Code §1542, which
    says: "A general release does not extend to claims which the creditor does
    not know or suspect to exist in his or her favor at the time of executing
    the release, which if known by him or her must have materially affected his
    settlement with the debtor." And, if you are not a California resident, you
    waive your rights under any applicable statutes of a similar effect, to the
    fullest extent permissible under applicable law.
</p>

<h1>14. Miscellaneous (Including Dispute Resolution and Arbitration)</h1>

<p><b>14.1. Notice</b></p>
<p>
    LearningQ, llc may provide you with notices, including those regarding
    changes to the Terms, by email, regular mail, postings on the Services, or
    other reasonable means.  Notice will be deemed given twenty-four hours after
    email is sent, unless LearningQ, llc is notified that the email address is
    invalid.  Alternatively, we may give you legal notice by mail to a postal
    address, if provided by you through the Services.  In such case, notice will
    be deemed given three days after the date of mailing. Notices posted on the
    Services are deemed given 30 days following the initial posting.   Any
    notices directed to LearningQ, llc shall be sent by first class U.S. Mail to
    LearningQ, llc at 1143 Asbury, Winnetka, IL 60093 with a copy sent by email
    to info@learningq.org.
</p>

<p><b>14.2. Waiver</b></p>
<p>
    The failure of LearningQ, llc to exercise or enforce any right or provision
    of the Terms will not constitute a waiver of such right or provision. Any
    waiver of any provision of the Terms will be effective only if in writing
    and signed by LearningQ, llc.
</p>

<p><b>14.3. Governing Law</b></p>
<p>
    The Terms will be governed by and construed in accordance with the laws of
    the State of California, without giving effect to any principles of
    conflicts of law that would cause the application of the laws of any other
    jurisdiction.
</p>

<p><b>14.4. Dispute Resolution and Arbitration</b></p>
<p>
    PLEASE READ THIS SECTION CAREFULLY BECAUSE IT AFFECTS YOUR RIGHTS. BY
    AGREEING TO BINDING ARBITRATION, YOU WAIVE YOUR RIGHT TO LITIGATE DISPUTES
    THROUGH A COURT AND TO HAVE A JUDGE OR JURY DECIDE YOUR CASE.
</p>

<p>
    14.4.1. Generally. In order to expedite and control the cost of disputes,
    LearningQ, llc and you agree that any legal or equitable claim, dispute,
    action or proceeding arising from or related to your use of the Services or
    these Terms (“Dispute”) will be resolved as follows to the fullest extent
    permitted by law:
</p>
<p>
    14.4.2. Notice of Dispute. In the event of a Dispute, you or LearningQ, llc
    must give the other a written statement that sets forth the name, address,
    and contact information of the party giving it, the facts giving rise to the
    Dispute, and a proposed solution (a “Notice of Dispute”). You must send any
    Notice of Dispute by first class U.S. Mail to LearningQ, llc at 1173 Asbury,
    Winnetka, IL 60093 with a copy sent by email to info@learningq.org. 
    LearningQ, llc will send any Notice of Dispute to you by first class U.S.
    Mail to your address if LearningQ, llc has it, or otherwise to your email
    address.  You and LearningQ, llc will attempt in good faith to resolve any
    Dispute through informal negotiation within sixty (60) days from the date
    the Notice of Dispute is sent. After sixty (60) days, you or LearningQ, llc
    may commence arbitration.
</p>
<p>
    14.4.3. Binding Arbitration. Any Dispute which has not been resolved by
    negotiation as provided herein within sixty (60) days or such time period as
    you and LearningQ, llc may otherwise agree, shall be finally resolved by
    binding arbitration as described in this Section 15.4 (Dispute Resolution
    and Arbitration).  You are giving up the right to litigate (or participate
    in as a party or class member) all Disputes in court before a judge or
    jury.  Instead, all Disputes will be resolved before a neutral arbitrator,
    whose decision will be final except for a limited right of appeal under the
    Federal Arbitration Act.  The place of arbitration shall be Santa Clara
    County, California.  Any court with jurisdiction over the parties may
    enforce the arbitrator's award.
</p>
<p>
    14.4.4. Class Action Waiver. Any proceedings to resolve or litigate any
    Dispute in any forum will be conducted solely on an individual basis. 
    Neither you nor LearningQ, llc will seek to have any Dispute heard as a
    class action or in any other proceeding in which either party acts or
    proposes to act in a representative capacity.  No arbitration or proceeding
    will be combined with another without the prior written consent of all
    parties to all affected arbitrations or proceedings. You may file a Dispute
    only on your own behalf and cannot seek relief that would affect other
    Users. If there is a final judicial determination that any particular
    Dispute cannot be arbitrated in accordance with the limitations of this
    Section 14.4 (Dispute Resolution and Arbitration), then only that Dispute
    may be severed and brought in court. All other Disputes remain subject to
    this Section 14.4 (Dispute Resolution and Arbitration).
</p>
<p>
    14.4.5. Arbitration Procedures. Any arbitration will be conducted by JAMS
    under the JAMS Comprehensive Arbitration Rules and Procedures (“JAMS Rules”)
    in effect at the time the Dispute is filed.  You may request a telephonic or
    in-person hearing by following the JAMS Rules.  In a dispute involving
    $10,000 or less, any hearing will be telephonic unless the arbitrator finds
    good cause to hold an in-person hearing instead. To the extent the forum
    provided by JAMS is unavailable, LearningQ, llc and you agree to select a
    mutually agreeable alternative dispute resolution service and that such
    alternative dispute resolution service shall apply the JAMS Rules.  The
    arbitrator may award the same damages to you individually as a court could. 
    The arbitrator may award declaratory or injunctive relief to you only
    individually, and only to the extent required to satisfy your individual
    claim.
</p>
<p>
    14.4.6. Arbitration Fees. Whoever files the arbitration will pay the initial
    filing fee.  If LearningQ, llc files, then LearningQ, llc will pay; if you
    file, then you will pay unless you get a fee waiver under the applicable
    arbitration rules.  Each party will bear the expense of that party's
    attorneys, experts, and witnesses, and other expenses, regardless of which
    party prevails, but a party may recover any or all expenses from another
    party if the arbitrator, applying applicable law, so determines.
</p>
<p>
    14.4.7. Filing Period.  To the extent permitted by law, any Dispute under
    these Terms must be filed within one (1) year in an arbitration proceeding. 
    The one-year period begins when the events giving rise to the Dispute first
    occur.  If a Dispute is not filed within one year, it is permanently barred.
</p>
<p>
    14.4.8. Venue. In the event that any Dispute cannot be resolved by binding
    arbitration in accordance with this Section 15.4 (Dispute Resolution and
    Arbitration), you agree that such Dispute will be filed only in the state or
    federal courts in and for Santa Clara County, California, and each of you
    and LearningQ, llc hereby consent and submit to the personal and exclusive
    jurisdiction of such courts for the purpose of litigating any such action.
    Notwithstanding this, LearningQ, llc shall still be allowed to apply for
    injunctive or other equitable relief to protect or enforce its intellectual
    property rights in any court of competent jurisdiction.
</p>

<p><b>14.5. Severability</b></p>
<p>
    If any provision of the Terms or any Guidelines is held to be unlawful,
    void, or for any reason unenforceable, then that provision will be limited
    or eliminated from the Terms to the minimum extent necessary and will not
    affect the validity and enforceability of any remaining provisions.
</p>

<p><b>14.6. Assignment</b></p>
<p>
    The Terms and related Guidelines, and any rights and licenses granted
    hereunder, may not be transferred or assigned by you without LearningQ,
    llc's prior written consent, but may be assigned by LearningQ, llc without
    consent or any restriction.  Any assignment attempted to be made in
    violation of the Terms shall be null and void.
</p>

<p><b>14.7. Survival</b></p>
<p>
    Upon termination of the Terms, any provision which, by its nature or express
    terms should survive, will survive such termination or expiration,
    including, but not limited to, Sections 2 (Privacy Policy), 4 (Modification
    of the Terms) through 6 (Digital Millennium Copyright Act), 7.1 (Proprietary
    Materials), 7.4 (Non-Commercial Use), 7.5 (Crediting LearningQ, llc), and 8
    (Prohibited Conduct) through 15 (Miscellaneous (Including Dispute Resolution
    and Arbitration)).
</p>

<p><b>14.8. Headings</b></p>
<p>
    The heading references herein are for convenience purposes only, do not
    constitute a part of the Terms, and will not be deemed to limit or affect
    any of the provisions hereof.
</p>

<p><b>14.9. Entire Agreement</b></p>
<p>
    The Terms, the Privacy Policy and Guidelines constitute the entire agreement
    between you and LearningQ, llc relating to the subject matter herein and
    will not be modified except in writing, signed by both parties, or by a
    change to the Terms, Privacy Policy or Guidelines made by LearningQ, llc as
    set forth in Section 4 (Modification of the Terms) above.
</p>

<p><b>14.10. Disclosures</b></p>
<p>
    The Services are hosted in the United States, and the services provided
    hereunder are offered by LearningQ, llc: 1143 Asbury, Winnetka, IL 60093.
</p>

<p>If you have any questions about our Terms of Use, please contact us at:</p>

<p>
    LearningQ<br />
    444 Kent Way<br />
    Zephyr Cove, NV 89448-0618<br />
    <a class="link" href="mailto:info@learningq.org"> info@learningq.org </a>
</p>

<style lang="scss" scoped>
    h1 {
        margin-bottom: 20px;
        font-size: 1.4rem;
    }
    .indent-1 {
        margin-left: 30px;
    }
</style>`
const Terms = () => {
  return (
    <>
      <SEO title="Terms of Service" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
                         {/* Start Slider Area  */}
                         <div className="slider-area slider-style-3 height-750  bg_image bg_image_fixed" data-black-overlay="2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="order-2 order-lg-1 col-lg-8 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner pt--80 text-left">
                                    <h1 className="title pt--80 theme-gradient">Terms of service.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}
          <div className="container">
         
          <Separator />
          { <div dangerouslySetInnerHTML={{ __html: rawHTML }} /> }
        </div>
   

        <FooterFour />
      </main>
    </>
  );
};

export default Terms;
