import React from "react";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderThree";
import FooterFour from "../../common/footer/FooterThreeAlt";
import ServiceOne from "../../elements/tab/TabThree";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import Portfolio from "../../elements/portfolio/Portfolio";
import PricingThree from "../../elements/pricing/PricingThree";
import ReactPlayer from "react-player";
import { FiArrowRight } from "react-icons/fi";
import Modal from "../../elements/modal/Modal";
import { useState } from "react";
import MainSignUp from "../../elements/forms/MainSignUp";
class ResponsivePlayer extends ReactPlayer {
  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/806125500"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    );
  }
}

const JobofGettingaJob = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  function showModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }
  return (
    <>
      <SEO title="LearningQ | Job of Getting a Job " />

      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
        {/* Start Slider Area  */}
        <div>
          <div
            className="container bg_image bg_image_fixed"
            data-black-overlay="4"
            style={{
              maxWidth: "100%",
              padding: "0",
              margin: "0",
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
            }}
          >
            <div className="row row--30 align-items-center">
              <div className="order-1 order-lg-1 col-lg-8">
                <div className="thumbnail">
                  <ResponsivePlayer />
                </div>
              </div>
              <div className="order-2 order-lg-2 col-lg-4 mt_md--50 mt_sm--50 mt_lg--30">
                <div
                  className="inner text-left"
                  style={{ padding: "60px 20px 20px 20px" }}
                >
                  <h1
                    className="title theme-gradient"
                    style={{ fontSize: "69px" }}
                  >
                    We've got a better way to learn
                  </h1>
                  <p
                    className="description"
                    style={{ fontSize: "15px", lineHeight: "19px" }}
                  >
                    We break complex concepts into 2-5 minute, video based skill
                    units unlocking better results for Learners, Instructors,
                    and Organizations
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                    <button className="btn-default" onClick={showModal}>
                      Get Started{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row mb--35">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="What's in The Job of Getting a Job"
                  description="Our newest certification is built for people who are interested in getting a job, and need to know what goes into searching, preparing, and interviewing for a new position. Take a look at some of the badges in the new certification."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style "
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */} <Separator />
        <div className="container">
          <Portfolio />
        </div>
        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--35">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Affordable Pricing Plan."
                  description="We help organizations succeed by extending services, <br /> optimizing coaching, and increasing engagement."
                />
              </div>
            </div>
            <PricingThree />
          </div>
        </div>
        {/* End Elements Area  */}
        <FooterFour />
      </main>
      <Modal isOpen={isModalOpen} title="Grant Sign Up" onClose={closeModal}>
        <MainSignUp></MainSignUp>
      </Modal>
    </>
  );
};

export default JobofGettingaJob;
