import React from "react";
import { FiCheck } from "react-icons/fi";
import Modal from "../../elements/modal/Modal";
import { useState } from "react";
import MainSignUp from "../../elements/forms/PoliceSignUp";

const PricingTwo = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  function showModal() {
    setModalOpen(true);
  }
  
  function closeModal() {
    setModalOpen(false);
  }
  return (
    <div className="row row--15">
  {/* Start PRicing Table Area  */}
  <div className="col-lg-4 col-md-6 col-12">
    <div className="rn-pricing style-2">
      <div className="pricing-table-inner">
        <div className="pricing-header">
          <h4 className="title">Self-Guided Learning</h4>
          <div className="pricing">
            <div className="price-wrapper">
              <span className="currency">$</span>
              <span className="price">100</span>
            </div>
            <span className="subtitle">USD Per Course</span>
          </div>
        </div>
        <div className="pricing-body">
          <ul className="list-style--1">
            <li>
              <FiCheck /> Full access to all course content for 3 months.
            </li>
            <li>
              <FiCheck /> Learn at your own pace.
            </li>
            <li>
              <FiCheck /> Ideal for independent learners.
            </li>
          </ul>
        </div>
        <div className="pricing-footer">
          <button className="btn-default" onClick={showModal}>
            Enroll Now
          </button>
        </div>
      </div>
    </div>
  </div>
  {/* End PRicing Table Area  */}

  {/* Start PRicing Table Area  */}
  <div className="col-lg-4 col-md-6 col-12">
    <div className="rn-pricing style-2 active">
      <div className="pricing-table-inner">
        <div className="pricing-header">
          <h4 className="title">Virtual Courses</h4>
          <div className="pricing">
            <div className="price-wrapper">
              <span className="currency">$</span>
              <span className="price">300</span>
            </div>
            <span className="subtitle">USD Per Course</span>
          </div>
        </div>
        <div className="pricing-body">
          <ul className="list-style--1">
            <li>
              <FiCheck /> Live sessions once a week with law enforcement instructors
            </li>
            <li>
              <FiCheck /> Expert training and insights.
            </li>
            <li>
           
              <FiCheck /> 10 weeks of live virtual instruction</li>
              <li><FiCheck />Full access to all course content for 3 months.
            </li>
            
            <li>
              <FiCheck /> Ideal for learners seeking engagement with instructors.
            </li>
          </ul>
        </div>
        <div className="pricing-footer">
          <button className="btn-default" onClick={showModal}>
            Enroll Now
          </button>
        </div>
      </div>
    </div>
  </div>
  {/* End PRicing Table Area  */}

  {/* Start PRicing Table Area  */}
  <div className="col-lg-4 col-md-6 col-12">
    <div className="rn-pricing style-2">
      <div className="pricing-table-inner">
        <div className="pricing-header">
          <h4 className="title">Organizational Support</h4>
          <div className="pricing">
            <div className="price-wrapper">
             
              <span className="price">Call</span>
            </div>
          </div>
        </div>
        <div className="pricing-body">
          <ul className="list-style--1">
            <li>
              <FiCheck /> Partnership with organizations.
            </li>
            <li>
              <FiCheck /> Support for live and virtual training.
            </li>
            <li>
              <FiCheck /> Customized workshops available.
            </li>
            <li>
              <FiCheck />Setup so you can offer live or virtual courses.
            </li>
          </ul>
        </div>
        <div className="pricing-footer">
          <button className="btn-default" onClick={showModal}>
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
  {/* End PRicing Table Area  */}
  <Modal isOpen={isModalOpen} title="Grant Sign Up" onClose={closeModal}>
     <MainSignUp />
   </Modal>
</div>
     
  );
};
export default PricingTwo;
