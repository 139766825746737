import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { FiFeather, FiUsers } from "react-icons/fi";

const TimelineData = [
  {
    id: "1",
    date: "Option-1",
    title: "Boost Existing Live and Online Classes",
    description:
      "Use LearningQ's content to assign pre-work or homework to complement your existing lesson plans. Get immediate feedback through the quizzes at the end of each learning unit.",
    icon: <FiFeather />,
  },
  {
    id: "2",
    date: "Option-2",
    title: "Expand your Impact with out of Network Virtual Classes",
    description:
      "It is estimated that 85% of people with disabilities don't have access to services. Limited opportunities exist to provide turn-key online programming for individuals outside of your community to address the need for an underserved population. You provide the instructor, and we help with outreach, registration, and content.",
    icon: <FiUsers />,
  },
];

const TimelineGrantPartner = () => {
  return (
    <div className="rn-timeline-wrapper timeline-style-one position-relative">
      <div className="timeline-line"></div>
      {/* Start Single Timeline  */}

      {TimelineData.map((data, index) => (
        <div className="single-timeline mt--50" key={index}>
          <div className="timeline-dot">
            <div className="time-line-circle"></div>
          </div>
          <div className="single-content">
            <div className="inner">
              <div className="row row--30 align-items-center">
                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                  <div className="content">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <span className="date-of-timeline">{data.date}</span>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <h2 className="title">{data.title}</h2>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <p className="description">{data.description}</p>
                    </ScrollAnimation>

                    <div className="read-morebtn">
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                      ></ScrollAnimation>
                    </div>
                  </div>
                </div>
                <div className="order-1 order-lg-2 col-lg-6">
                  <div className="icon">{data.icon}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* End Single Timeline  */}
    </div>
  );
};

export default TimelineGrantPartner;
