import React from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderThree";
import FooterFour from "../common/footer/FooterThreeAlt";
import Separator from "../elements/separator/Separator";




const rawHTML = `<p><b>Last updated: January 2023</b></p>

<p>
    This Cookie Policy explains how LearningQ LLC ("LearningQ", "we", "us" and
    "ours") use cookies and similar technologies to recognise you when you visit
    our website at https://app.learningq.org ("Website"). It explains what these
    technologies are and why we use them, as well as your rights to control our
    use of them.
</p>

<p><b>What are cookies?</b></p>

<p>
    Cookies are small data files that are placed on your computer or mobile
    device when you visit a website. Website owners can use cookies for a
    variety of reasons that can include enabling their websites to work (or work
    more efficiently), providing personalised content and advertising, and
    creating website analytics.
</p>

<p>
    Cookies set by the website owner (in this case, LearningQ) are called "first
    party cookies". Only the website owner can access the first party cookies it
    sets. Cookies set by parties other than the website owner are called "third
    party cookies". Third party cookies enable third party features or
    functionality to be provided on or through the website (e.g. like
    advertising, interactive content and social sharing). The parties that set
    these third party cookies can recognise your device both when it visits the
    website in question and also when it visits other websites that have
    partnered with them.
</p>

<p><b>Why do we use cookies?</b></p>

<p>
    We use first party and third party cookies for several reasons. Some cookies
    are required for technical reasons that are strictly necessary for our
    Website to operate, and we refer to these as "essential" cookies. Other
    cookies also enable us to provide Website functionality, or to enhance
    visitors' experience on our Website by providing them with personalised
    settings.
</p>

<p><b>What about other tracking technologies, like web beacons?</b></p>

<p>
    Cookies are not the only way to recognise or track visitors to a website. We
    may use other, similar technologies from time to time, like web beacons
    (sometimes called "tracking pixels" or "clear gifs"). These are tiny
    graphics files that contain a unique identifier that enable us to recognise
    when someone has visited our Website. This allows us, for example, to
    monitor the traffic patterns of users from one page within our Website to
    another, to deliver or communicate with cookies, to understand whether you
    have come to our Website from an online advertisement displayed on a
    third-party website, to improve site performance. In many instances, these
    technologies are reliant on cookies to function properly, and so declining
    cookies will often impair their functioning.
</p>

<p><b>How can you control cookies?</b></p>

<p>
    You have the right to decide whether to accept or reject cookies. You may
    stop or restrict the placement of Technologies on your device or remove them
    by adjusting your preferences as your browser or device permits. However, if
    you adjust your preferences, our Services may not work properly.
</p>

<p><b>How often will we update this Cookie Policy?</b></p>

<p>
    We may update this Cookie Policy from time to time in order to reflect, for
    example, changes to the cookies we use or for other operational, legal or
    regulatory reasons. Please therefore re-visit this Cookie Notice regularly
    to stay informed about our use of cookies and related technologies.
</p>

<p>
    The date at the top of this Cookie Policy indicates when it was last
    updated.
</p>

<p><b>Where can you get further information?</b></p>

<p>
    If you have any questions about our use of cookies or other technologies,
    please contact us at:
</p>

<p>
    LearningQ<br />
    444 Kent Way<br />
    Zephyr Cove, NV 89448-0618<br />
    <a class="link" href="mailto:info@learningq.org"> info@learningq.org </a>
</p>
Footer
© 2023 GitHub, Inc.
Footer navigation
Terms
Privacy


<style lang="scss" scoped>
    h1 {
        margin-bottom: 20px;
        font-size: 1.4rem;
    }
    .indent-1 {
        margin-left: 30px;
    }
</style>`
const Cookie = () => {
  return (
    <>
      <SEO title="Terms of Service" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
                         {/* Start Slider Area  */}
                         <div className="slider-area slider-style-3 height-750  bg_image bg_image_fixed" data-black-overlay="2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="order-2 order-lg-1 col-lg-8 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner pt--80 text-left">
                                    <h1 className="title pt--80 theme-gradient">Cookie Policy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}
          <div className="container">
         
          <Separator />
          { <div dangerouslySetInnerHTML={{ __html: rawHTML }} /> }
        </div>
   

        <FooterFour />
      </main>
    </>
  );
};

export default Cookie;
