import React from "react";
import IframeResizer from "iframe-resizer-react";

const FiveDayTrial = () => {
  return (
    <IframeResizer
      log
      bodyMargin="0px"
      bodyPadding="0px"
      widthCalculationMethod="max"
      sizeWidth={true}
      style={{
        width: "100%",
        height: "1000px",
        maxWidth: "1320px",
        margin: "0 auto",
        overflow: "auto",
        border: "none",
      }}
      title="5 Day Trial"
      src="https://forms.monday.com/forms/embed/9d4482ec9aa38dbfad271bbfd0f80958?r=use1"
    ></IframeResizer>
  );
};

export default FiveDayTrial;
