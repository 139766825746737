import React from "react";
import IframeResizer from "iframe-resizer-react";

const PoliceSignUp = () => {
  return (
    <IframeResizer
      log
      bodyMargin="0px"
      bodyPadding="0px"
      widthCalculationMethod="max"
      sizeWidth={true}
      style={{
        width: "100%",
        height: "1000px",
        maxWidth: "1320px",
        margin: "0 auto",
        overflow: "auto",
        border: "none",
      }}
      title="Safety and Police"
      src="https://forms.monday.com/forms/embed/c325c9cf4eb49d2a23da5b8da253c762"
    ></IframeResizer>
  );
};

export default PoliceSignUp;
