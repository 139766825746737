import React from 'react';
import {useEffect} from 'react';

const Darkmode = () => {
   
    useEffect(() => {
    document.body.classList.add('light');
  }, []);
	
    return (
        <div>
          
        </div>
    )
}
export default Darkmode;