import React from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import PortfolioData from "../data/portfolio/HowData.json";
import HowtoDetailsContent from "../elements/portfolio/HowToDetailsContent";

const HowToDetails = ({
  match: {
    params: { id },
  },
}) => {
  const portfolioId = parseInt(id, 10);
  const data = PortfolioData.filter(
    (portfolio) => portfolio.id === portfolioId
  );
  return (
    <>
      <SEO title="LearningQ || Getting Sarted" />
      <Layout>
        <HowtoDetailsContent data={data[0]} />
      </Layout>
    </>
  );
};

export default HowToDetails;
