import React from "react";
import SEO from "../common/SEO";
import HeaderGrant from "../common/header/HeaderGrant";
import FooterFour from "../common/footer/FooterThreeAlt";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Brand from "../elements/brand/Brand";
import Split from "../elements/split/SlipFour";
import { Link } from "react-router-dom";

const Grant = () => {
  return (
    <>
      <SEO title="LearningQ | Grant Award Details " />
      <main className="page-wrapper">
        <HeaderGrant btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Slider Area  */}
        <div>
          <div
            className="container"
            style={{ maxWidth: "100%", padding: "0", margin: "0" }}
          >
            <Split />
          </div>
        </div>
        {/* End Slider Area  */}

        {/* start Description Area  */}
        <div className="slider-area slider-style-3 height-650 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title" style={{ color: "#EE6C4D" }}>
                    LearningQ is looking for partners and participants to test
                    innovative new content for people with disabilities.
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  In June, 2023, LearningQ received two Federal grants from the
                  National Institute on Disability, Independent Living, and
                  Rehabilitation Research's (NIDILRR) Small Business Innovation
                  Research program. These grants provide for 600 individuals to
                  receive a yearlong scholarship to LearningQ's new content to
                  research the adoption and engagement of online learning by
                  people with disabilities.
                </p>
              </div>
            </div>
            <div className="row">
              <Brand />
            </div>
          </div>
        </div>

        {/* end Description Area  */}
        <Separator />
        <div className="container text-center">
          <div className="rn-section-gap">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="	LearningQ Grant Overview"
                  description="We are looking for program participants and agencies to work with LearningQ from August through October, 2023. Participants will have access to the LearningQ platform and content through August, 2024."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 align-items-center">
                <Link
                  style={{ margin: "0 auto" }}
                  className="btn-default"
                  to="grant/Study"
                >
                  Read Program Specifics
                </Link>
              </div>
            </div>
          </div>
        </div>
        <FooterFour />
      </main>
    </>
  );
};

export default Grant;
