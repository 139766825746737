import React from 'react';


const PortfolioPaper= ({ portfolio }) => {
    return (
        <div className="rwt-card">
            <div className="inner">
                <div className="thumbnail">
                    <a href={`/${portfolio.url}.pdf`}><figure className="card-image">
                      	
                        <img src={`./images/whitepaper/${portfolio.image}.jpg`} alt="{portfolio.title}" /> 
                    
                    </figure></a>	
                    
                </div>
                <div className="content">
                    <a href={`/${portfolio.url}.pdf`}><h5 className="title mb--10">
                       
                            {portfolio.title}
                       
                    </h5></a>
                    <span className="subtitle b2 text-capitalize">{portfolio.category}</span>
                </div>
            </div>
        </div>
    )
}
export default PortfolioPaper;