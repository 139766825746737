import React from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderThree";
import FooterFour from "../common/footer/FooterThreeAlt";
import ServiceOne from "../elements/tab/TabThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Portfolio from "../elements/portfolio/Portfolio";
import PricingThree from "../elements/pricing/PricingThree";
import ReactPlayer from "react-player";
import { FiArrowRight } from "react-icons/fi";

class ResponsivePlayer extends ReactPlayer {
  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/806125500"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    );
  }
}

const SignUp = () => {
  return (
    <>
      <SEO title="LearningQ | Job of Getting a Job " />

      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-3 height-750  bg_image bg_image_fixed"
          data-black-overlay="2"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-1 order-lg-1 col-lg-7">
                <div className="thumbnail">
                  <ResponsivePlayer />
                </div>
              </div>
              <div className="order-2 order-lg-2 col-lg-5 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-left">
                  <h1
                    className="title theme-gradient"
                    style={{ fontSize: "69px" }}
                  >
                    The Job of <br /> Getting a job
                  </h1>
                  <p
                    className="description"
                    style={{ fontSize: "15px", lineHeight: "19px" }}
                  >
                    At LearningQ, we're excited to offer our Job of Getting a
                    Job certification. Our program covers every step involved in
                    finding and landing your dream job, from identifying your
                    career interests to negotiating a job offer and everything
                    in between. Whether you're a first time worker or you've
                    been out of the workforce for a while, our program is
                    designed to provide you with the skills and knowledge you
                    need to succeed.
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                    <a
                      className="btn-default btn-medium btn-icon"
                      target="_blank"
                      href="https://forms.monday.com/forms/9d4482ec9aa38dbfad271bbfd0f80958?r=use1"
                      rel="noreferrer"
                    >
                      Free 5-day Trial{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row mb--35">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="What's in The Job of Getting a Job"
                  description="Our newest certification is built for people who are interested in getting a job, and need to know what goes into searching, preparing, and interviewing for a new position. Take a look at some of the badges in the new certification."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style "
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */} <Separator />
        <div className="container">
          <Portfolio />
        </div>
        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--35">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Affordable Pricing Plan."
                  description="We help organizations succeed by extending services, <br /> optimizing coaching, and increasing engagement."
                />
              </div>
            </div>
            <PricingThree />
          </div>
        </div>
        {/* End Elements Area  */}
        <FooterFour />
      </main>
    </>
  );
};

export default SignUp;
