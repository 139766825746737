import PropTypes from "prop-types";
import React from 'react'
import Page from "../components/Page"; 

const SEO = ( {title} ) => {
	console.log(title)
	Page(title)
    return (
        <>
			
            <meta charSet="utf-8" />
            <title>{title} || LearningQ Life-skills training </title>
            <meta name="robots" content="follow" />
			<meta name="description" content="LearningQ Micro-Learning, Life Skills Training" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


