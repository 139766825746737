import React from "react";
import ReactModal from "react-modal";

const Modal = ({ isOpen, title, onClose, children }) => {
  function closeModal() {
    onClose && onClose();
  }

  return (
    <ReactModal
      isOpen={isOpen}
      className="modal-dialog modal-xl"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <button
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">{children}</div>
    </ReactModal>
  );
};

export default Modal;
