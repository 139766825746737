import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionWFB = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            What is microlearning?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            Microlearning is the idea of breaking down complex topics into
            short, engaging snippets of learning that are focused and easy to
            use.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            What are the benefits of microlearning?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            Learners who use microlearning show a higher retention rate of the
            information they learn and they can easily repeat specific units to
            refresh or reinforce learnings on a specific topic.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            Why does LearningQ use video?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            In general, people retain about 10% of visual information, 30%-40%
            of visual and auditory content. However, that number jumps to 90% of
            the information if we see and hear simultaneously.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            What are micro-units, badges and certificates?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            Micro-units are the 60-90 second videos used by LearningQ. They form
            the building blocks of badges within a larger program. Micro-units
            can be used across multiple badges. Learners who complete all the
            badges within a curriculum get a certificate.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionWFB;
