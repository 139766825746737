import React from 'react';
import AccordionOne from '../accordion/AccordionOne';

const AbouThree = () => {
    return (
        <div className="rn-about-area about-style-3 rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    
                    <div className="col-lg-11 mt_md--40 mt_sm--40">
                        <div className="inner">
                            <div className="section-title">
                                <h2 className="title mb--40">Frequent Questions</h2>
                            </div>
                            <AccordionOne customStyle="" />
                        </div>
                    </div>
                </div>
            </div>                        
        </div>
    )
}

export default AbouThree
