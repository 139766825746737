import React from "react";
import { Link } from "react-router-dom";

const callToActionData = {
  title: "Ready to get started?",
  subtitle: "Let's find the best fit for your needs.",
  btnText: "Let's Chat",
};

const CalltoActionClasses = () => {
  return (
    <div
      className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
      }}
      data-black-overlay="3"
    >
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-10 offset-lg-1">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title mb--0">{callToActionData.title}</h2>
                <p>
                  We'd like to get onboard with us so you can participate with
                  us in this exciting opportunity
                </p>
                <div className="call-to-btn text-center">
                  <Link className="btn-default btn-large" to={"/grant/classes"}>
                    Browse Classes
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionClasses;
