import React from "react";
import ReactPlayer from "react-player";

const PortfolioDetailsContent = ({ data }) => {
  return (
    <div className="rwt-portfolio-details rn-section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="inner">
              <div className="details-list">
                <div className="thumbnail alignwide">
                  <div className="player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      url={data.video}
                      width="100%"
                      height="100%"
                      controls={true}
                    />
                  </div>
                </div>

                <div className="row mt--40 row--30">
                  <div className="col-lg-6 mt_md--30 mt_sm--30">
                    <div className="content-right">
                      <div className="thumbnail">
                        <img
                          className="radius w-100"
                          src={data.portfolioImage}
                          alt="Class Detial"
                        />
                      </div>

                      <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: data.body }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="content-left">
                      <div className="single-list-wrapper">
                        <div className="single-list">
                          <label> Start Date:</label>
                          <span>{data.startdate}</span>
                        </div>
                        <div className="single-list">
                          <label> End Date:</label>
                          <span>{data.enddate}</span>
                        </div>
                        <div className="single-list">
                          <label> Schedule:</label>
                          <span>{data.schedule}</span>
                        </div>
                        <div className="single-list">
                          <label> Time:</label>
                          <span>{data.time}</span>
                        </div>
                        <div className="single-list">
                          <label> Cost:</label>
                          <span>{data.cost}</span>
                        </div>
                        <div className="single-list">
                          <label>Contact:</label>
                          <span>
                            <a
                              className="btn-default btn-small"
                              href={`${data.contactemail}`}
                            >
                              {data.contact}
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className="view-btn mt--50">
                        <a
                          className="btn-default btn-large round"
                          href={`${data.btn.link}`}
                        >
                          {data.btn.buttontext}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetailsContent;
