import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FiActivity, FiKey, FiShield, FiStar, FiUser } from "react-icons/fi";

const TabSPE = () => {
  return (
    <div>
      <div className="row" >
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--0  rn-default-tab style-one">
            <div className="col-lg-5 no-padding">
                <div className="tab-button-panel">
                  <div className="tab-button-top">
                  <h3>What You'll Learn</h3>
                    <p className="description">Our program covers topics to help individuals feel safe during police interactions. As you progress, you'll earn badges reflecting your expertise, with skill units that break down complex concepts into simple skills.</p>
                  </div>
                  <TabList className="tab-button wfcontent">
                  <Tab>
  
  <div className="rn-tab-button">
  <div className="icon" style={{
    background: "var(--color-tertiary-darker)"
  }}><FiStar/></div>
  <div className="content"> 
  <p className="title">You and the Police</p>
    <p className="description">Understand the role of law enforcement and be prepared for everyday interactions.</p>
    </div>
   
  </div>
</Tab>
<Tab>
  <div className="rn-tab-button">
  <div className="icon" style={{
    background: "var(--color-tertiary-darker)"
  }}><FiShield/></div>
  <div className="content"> 
    <p className="title">Being Safe with Police</p>
    <p className="description">Learn how to stay calm and follow guidelines during stressful encounters.</p>
  </div></div>
</Tab>
<Tab>
  <div className="rn-tab-button">
  <div className="icon" style={{
    background: "var(--color-tertiary-darker)"
  }}><FiUser /></div>
  <div className="content"> 
    <p className="title">Proper Identification</p>
    <p className="description">Recognize officers and how to safely present your ID to avoid misunderstandings.</p>
  </div></div>
</Tab>
<Tab>
  <div className="rn-tab-button">
  <div className="icon" style={{
    background: "var(--color-tertiary-darker)"
  }}><FiKey/></div>
  <div className="content"> 
    <p className="title">Your Rights</p>
    <p className="description">Know your legal rights and how to handle searches or questioning confidently.</p>
  </div></div>
</Tab>
<Tab>
  <div className="rn-tab-button">
  <div className="icon" style={{
    background: "var(--color-tertiary-darker)"
  }} ><FiActivity /></div>
  <div className="content"> 
    <p className="title">Police Encounters</p>
    <p className="description">Prepare for specific situations like traffic stops, searches, and arrests.</p>
  </div></div>
</Tab>
                  </TabList>
                </div>
              </div>
              <div className="col-lg-7" style={{
                background:"#f5f8fa"
              }}>
                <div className="tab-content-panel">
                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                       
                          <div className="col-lg-12 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                    <h2 className="title">
                                    You and the Police
                                      </h2>
                                    
                                      <ul>
      <li><strong>What Do Police Officers Do in Our Community?</strong><br />
      Explore the wide range of duties performed by police officers, from ensuring public safety to engaging with the community.</li>
      <li><strong>Why a Police Officer May Want to Talk with You</strong><br />
      Discover typical reasons for police interactions and the best ways to approach these situations.</li>
      <li><strong>What is a K-9 Unit?</strong><br />
      Learn about the role of police dogs and how they assist in various law enforcement tasks.</li>
      <li><strong>Completing a Safety Disclosure Card. and How it Helps</strong><br />
      Find out how using a safety disclosure card can make police encounters smoother and safer for everyone involved.</li> 
      <li><strong>Visiting Your Local Police Department</strong><br />
      Get familiar with what to expect when visiting a police station and understand the purpose behind each part of the visit.</li>
      <li><strong>The Three C's of Safety with a Police Officer</strong><br />
      Master the Three C's: Calm, Clear, Cooperative—key behaviors that promote safer and more effective police interactions.</li>
    </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                        
                          <div className="col-lg-12 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                    <h2 className="title">
                                    Being Safe with Police
                                      </h2>
                                     
                                      <ul>
      <li><strong>Situations and Stressors</strong><br />
      Identify the most common stress points in police interactions and explore practical strategies for managing anxiety.</li>
      
      <li><strong>Why Does the Police Officer Need to See My Hands?</strong><br />
      Understand the significance of visible hands and how it impacts your safety and the officer's comfort.</li>
      
      <li><strong>Techniques to Stay Calm</strong><br />
      Learn methods for maintaining composure during high-pressure moments, ensuring clear communication and cooperation.</li>
      
      <li><strong>Box Breathing</strong><br />
      Practice a simple breathing technique designed to help reduce stress and increase focus when dealing with law enforcement.</li>
      
      <li><strong>When a Police Officer May Use Force (Including Handcuffs, Takedowns, and Resistance)</strong><br />
      Gain insight into the scenarios where police might use force and learn how to reduce the chance of escalation.</li>
    </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                       
                          <div className="col-lg-12 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                    <h2 className="title">
                                    Proper Identification
                                      </h2>
                                     
                                      <ul>
      <li><strong>How Do I Know If They Are a Police Officer?</strong><br />
      Learn how to identify real police officers by their uniforms, badges, and other key indicators.</li>
      <li><strong>What Type of ID Should I Have and When?</strong><br />
      Understand the types of identification you should carry and the appropriate times to present them.</li>    
      <li><strong>How to Stay Safe While Getting Your ID</strong><br />
      Follow simple steps for retrieving and presenting your ID to ensure safety for both you and the officer.</li>   
      <li><strong>Using Your Saftey Disclosure Card</strong><br />
      Communicate your specific needs clearly and effectively by using your safety disclosure card.</li>
    </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                         
                          <div className="col-lg-12 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                    <h2 className="title">
                                    Your Rights
                                      </h2>
                                    
                                      <ul>
      <li><strong>Your Miranda Rights</strong><br />
      Learn what it means when your rights are read and how they apply when you’re in police custody.</li> 
      <li><strong>Understanding Search and Consent</strong><br />
      Understand when police are allowed to search and how your consent plays a role in these situations.</li>
      <li><strong>Who to Call in an Emergency</strong><br />
      Develop a personalized plan to prepare for any police encounter, ensuring you're ready to act calmly and responsibly.</li>
      <li><strong>Your Rights During an Official Police Interview/Interrogation</strong><br />
      Know what to expect during a police interview and how to assert your legal rights during questioning.</li>
    </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                         
                          <div className="col-lg-12 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                    <h2 className="title">
                                    Police Encounters
                                      </h2>
                                    
                                      <ul>
      <li><strong>Traffic Stops and Searches of Car</strong><br />
      Know what to expect during a traffic stop and how to handle vehicle searches with confidence.</li>
      <li><strong>Pat and Frisk</strong><br />
      Learn the reasoning behind this type of search and how to cooperate while ensuring your own safety.</li>
      <li><strong>Home Visit/Search</strong><br />
      Understand your rights when police visit or search your home, and learn how to handle the situation smoothly.</li>
      <li><strong>Pedestrian Stops</strong><br />
      Be prepared for on-foot encounters with police by understanding what may prompt such a stop and how to respond safely.</li>
      <li><strong>What is Voluntary Contact?</strong><br />
      Learn about informal interactions with police and how to manage these encounters while staying calm and clear.</li>
      <li><strong>What is Investigative Detention?</strong><br />
      Discover why you may be detained for investigation and how to protect your rights while complying with police requests.</li>
      <li><strong>What is an Arrest?</strong><br />
      Get informed about the arrest process, including your rights before, during, and after being taken into custody.</li>
      <li><strong>When Do Police Use Force?</strong><br />
      Explore the circumstances in which law enforcement may apply force and how you can safely handle such situations.</li>
    </ul>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          
                          <div className="col-lg-12 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                    <h2 className="title">
                                    Police Encounters
                                      </h2>
                                     
                                      <ul>
      <li><strong>Traffic Stops and Searches of Car</strong><br />
      Know what to expect during a traffic stop and how to handle vehicle searches with confidence.</li>
      
      <li><strong>Pat and Frisk</strong><br />
      Learn the reasoning behind this type of search and how to cooperate while ensuring your own safety.</li>
      
      <li><strong>Home Visit/Search</strong><br />
      Understand your rights when police visit or search your home, and learn how to handle the situation smoothly.</li>
      
      <li><strong>Pedestrian Stops</strong><br />
      Be prepared for on-foot encounters with police by understanding what may prompt such a stop and how to respond safely.</li>
      
      <li><strong>What is Voluntary Contact?</strong><br />
      Learn about informal interactions with police and how to manage these encounters while staying calm and clear.</li>
      
      <li><strong>What is Investigative Detention?</strong><br />
      Discover why you may be detained for investigation and how to protect your rights while complying with police requests.</li>
      
      <li><strong>What is an Arrest?</strong><br />
      Learn about the arrest process, including your rights before, during, and after being taken into custody.</li>
      
      <li><strong>When Do Police Use Force?</strong><br />
      Explore the circumstances in which law enforcement may apply force and how you can safely handle such situations.</li>
    </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
             
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabSPE;
