import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
ReactGA.initialize("G-QT18L48KVQ");
const Tracker = ({ history, location }) => {
  useEffect(
    function () {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      ReactGA.send("pageview");

      history.listen((location, action) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
        ReactGA.send("pageview");
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  return null;
};

export default withRouter(Tracker);
