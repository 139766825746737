import React from 'react';
import SEO from "../../common/SEO";
import FooterThreeAlt from '../../common/footer/FooterThreeAlt';
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import HeaderTwo from '../../common/header/HeaderTwo';

const Contact = () => {
    return (
        <>
        <title> LeaningQ Contact Us</title>    
		<SEO title="LearningQ | Contact Us | Life Skills Training" />
            <main className="page-wrapper">
              
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
               
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        title = "We can't wait to hear from you."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
		     <FooterThreeAlt />
             </main>
            
        </>
    )
}
export default Contact;