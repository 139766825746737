import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const callToActionData = {
  title: "10 Seats Free for 412AbilityTech Members",
  subtitle:
    "LearningQ is offering a free trial class with up to 10 seats that are valid for 12 weeks. Try our tools and see their power. <b> Enter code 412Ability at sign up to claim your rewards</b> Offer good till 2/29/24",
  btnText: "Get Started",
};

const CalltoActionFive = () => {
  return (
    <div className="rn-callto-action clltoaction-style-default style-5">
      <div className="container">
        <div className="row row--0 align-items-center content-wrapper theme-shape">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h2 className="title">{callToActionData.title}</h2>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h6 className="subtitle">
                    LearningQ is offering a free trial class with up to 10 seats
                    that are valid for 12 weeks. Try our tools and see their
                    power. <br />
                    <br />
                    <b className="theme-gradient">
                      {" "}
                      Enter code "412Ability" at sign up to claim your rewards
                    </b>
                    <br />
                    Offer good till 2/29/24
                  </h6>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionFive;
