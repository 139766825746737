import React from 'react';
import ContentForm from "./ContentForm";

const ContentOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
		                    <div className="col-lg-4 col-md-4 col-12">
								<img className="radius-small" src='./images/banner/big_q.png' alt="Microlearing Lifeskills Traning" />
                                    <div className="inner">
                                        <h4 className="title">Let us help you:</h4>
                                        <ul>
											<li>Build interactive content that engages both participants and staff</li>
											<li>Deliver consistent and effective development to a wide variety of learners</li>
											<li>Bring your content to a wide audience of potential learners</li>
										</ul>
                                    </div>
                         
                               
                            </div>
                            <div className="col-lg-8 col-md-8 col-12">
								<ContentForm formStyle="contact-form-1" />
                                
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContentOne;