import React from "react";
import { Link } from "react-router-dom";

const PortfolioClass = ({ portfolio }) => {
  return (
    <div className="rwt-card">
      <div className="inner">
        <div className="thumbnail">
          <figure className="card-image">
            <Link to={`/classes/class-details/${portfolio.id}`}>
              <img
                className="img-fluid"
                src={portfolio.portfolioImage}
                alt="Portfolio-01"
              />
            </Link>
          </figure>
          <Link
            to={`/classes/class-details/${portfolio.id}`}
            className="rwt-overlay"
          ></Link>
        </div>
        <div className="content">
          <h5 className="title mb--10 text-capitalize">
            <Link to={`/classes/class-details/${portfolio.id}`}>
              {portfolio.category}
            </Link>
          </h5>
          <span className="subtitle b2 text-capitalize">
            Start Date: {portfolio.startdate}
            <br />
            Schedule: {portfolio.schedule}
            <br />
            Time:{portfolio.time}
            <br />
            Cost:{portfolio.cost}
          </span>
        </div>
      </div>
    </div>
  );
};
export default PortfolioClass;
