import React from 'react';
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioTwo from "./PortfolioTwo";

const PortfolioThreeColumn = () => {
    return (
        <>
                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "We want to be a catalyst for change."
                                            description = ""
                                        />
                                </div>
                            </div>
                            <PortfolioTwo Column="col-lg-4 col-md-6 mt--30 portfolio"  />
                        </div>
                    </div>
                </div>
            
        </>
    )
}
export default PortfolioThreeColumn;
