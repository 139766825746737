import React from 'react'
import HeaderTwo from '../common/header/HeaderTwo';
import FooterFour from '../common/footer/FooterThree';



const Layout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                <FooterFour />
            </main>
        </>
    )
}
export default Layout;
