import React from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterFour from "../common/footer/FooterThree";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import AboutTwo from "../elements/about/AboutTwo";
import Separator from "../elements/separator/Separator";
import TeamOne from "../elements/team/TeamOne";

const AboutUs = () => {
  return (
    <>
      <SEO title="LeaningQ | About Us | Lifeskills, Workforce Training" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-3 height-750  bg_image bg_image_fixed"
          data-black-overlay="2"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="order-2 order-lg-1 col-lg-8 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner pt--80 text-left">
                  <h1 className="title pt--80 theme-gradient">
                    The better way to
                    <br />
                    train life&nbsp;skills.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-counterup-area pb--100 rn-section-gap">
          <div className="container mt_dec--30">
            <CounterUpTwo
              column="col-lg-4 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-2 color-variation"
              textALign="text-center"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />
        <div
          className="slider-area slider-style-3 height-650  bg_image bg_image_fixed"
          data-black-overlay="2"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <AboutTwo />
            </div>
          </div>
        </div>
        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Experts."
                  title="Our Team."
                  description=""
                />
              </div>
            </div>
            <TeamOne
              column="col-lg-4 col-md-6 col-12 mt--30"
              teamStyle="team-style-default"
            />
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Flexibility, Engagement, Retention"
                  description="We offer an accessible learning platform with access to highly engaging lifeskills development, curriculum development services to build rich interactive content, and a robust network of learners eager to engage."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style "
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <FooterFour />
      </main>
    </>
  );
};

export default AboutUs;
