import React from "react";
import ReactPlayer from "react-player";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../../common/SEO";
import HeaderGrant from "../../common/header/HeaderGrant";
import FooterFour from "../../common/footer/FooterThreeAlt";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import TimelineGrantStudy from "../../elements/timeline/TimelineGrantStudy";
import AccordionStudy from "../../elements/accordion/AccordionStudy";

import Separator from "../../elements/separator/Separator";

class ResponsivePlayer extends ReactPlayer {
  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/845385102"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    );
  }
}

const Study = () => {
  return (
    <>
      <SEO title="LearningQ | Grant Details " />
      <main className="page-wrapper">
        <HeaderGrant btnStyle="btn-small" HeaderSTyle="header-transparent" />
        {/* Start Slider Area  */}
        <div>
          <div
            className="container bg_image bg_image_fixed"
            data-white-overlay="6"
            style={{
              maxWidth: "100%",
              padding: "0",
              margin: "0",
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
            }}
          >
            <div className="row row--30 align-items-center">
              <div className="order-1 order-lg-1 col-lg-8">
                <div className="thumbnail">
                  <ResponsivePlayer />
                </div>
              </div>
              <div className="order-2 order-lg-2 col-lg-4 mt_md--50 mt_sm--50 mt_lg--30">
                <div
                  className="inner text-left"
                  style={{ padding: "60px 20px 20px 20px" }}
                >
                  <h1
                    className="title theme-gradient"
                    style={{ fontSize: "69px" }}
                  >
                    We've got a better way to learn
                  </h1>
                  <p
                    className="description"
                    style={{ fontSize: "15px", lineHeight: "19px" }}
                  >
                    We break complex concepts into 2-5 minute, video based skill
                    units unlocking better results for Learners, Instructors,
                    and Organizations
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                    <a
                      className="btn-default btn-medium btn-icon"
                      target="_blank"
                      href="https://forms.monday.com/forms/9d4482ec9aa38dbfad271bbfd0f80958?r=use1"
                      rel="noreferrer"
                    >
                      Reqest a Demo{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Slider Area  */}
        {/* Start Service Area  */}
        <div
          className="slider-area slider-style-3 height-650  bg_image bg_image_fixed"
          data-black-overlay="3"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title" style={{ color: "#EE6C4D" }}>
                    LearningQ has been awarded two Federal Grants from NIDILRR's
                    Small Business Innovation Research program and is seeking
                    program participants for our research project.
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  LearningQ will use these Phase 1 grants to develop two
                  video-based microlearning curricula – Workforce Basics and
                  Healthy Cooking Basics – and to work with agencies and
                  learners to test the curricula and platform. LearningQ
                  proposes that having access to online microlearning, designed
                  to supplement coaching conducted by agencies, will increase
                  the skill development, knowledge base, and retention by
                  individuals with disabilities.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="LearningQ Project Timeline"
                  description="Our program is running from June through November, 2023. The period we are requesting that participants be on and actively using the platform are September and October, 2023. "
                />
              </div>
            </div>
            <TimelineGrantStudy textAlign="text-center" />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <div className="rn-about-area about-style-3 rn-section-gap">
          <div className="container">
            <div className="row row--30">
              <div className="col-lg-11 mt_md--40 mt_sm--40">
                <div className="inner">
                  <div className="section-title">
                    <h2 className="title mb--40">Frequent Questions</h2>
                  </div>
                  <AccordionStudy customStyle="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterFour />
      </main>
    </>
  );
};

export default Study;
