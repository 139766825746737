import React from "react";
import IframeResizer from "iframe-resizer-react";

const GrantSignUp = () => {
  return (
    <IframeResizer
      log
      bodyMargin="0px"
      bodyPadding="0px"
      widthCalculationMethod="max"
      sizeWidth={true}
      style={{
        width: "100%",
        height: "90vh",
        maxHeight: "1000px",
        maxWidth: "1320px",
        margin: "0 auto",
        overflow: "auto",
        border: "none",
      }}
      title="Grant Sign up"
      src="https://forms.monday.com/forms/embed/b1cf67609669065ac88a58f23aafc870?r=use1"
    ></IframeResizer>
  );
};

export default GrantSignUp;
