import React, { useState } from "react";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderThree";
import FooterFour from "../../common/footer/FooterThreeAlt";
import Separator from "../../elements/separator/Separator";
import Modal from "../../elements/modal/Modal";
import SignUpForm from "../../elements/forms/FiveDayTrial";
import Mailchimp from "react-mailchimp-form";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";

const Test = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);

  function showModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }
  function showSignUpModal() {
    setSignUpModalOpen(true);
  }

  function closeSignUpModal() {
    setSignUpModalOpen(false);
  }

  return (
    <>
      <SEO title="Test Page" />

      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
        <div
          className="slider-area slider-style-3 height-750  bg_image bg_image_fixed"
          data-black-overlay="2"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="order-2 order-lg-1 col-lg-8 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner pt--80 text-left">
                  <h1 className="title pt--80 theme-gradient">
                    Terms of service.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="containe text-center">
          <Separator />
          <br />
          <br />
          A test page to demostrate the usage of the modal.
          <br />
          <br />
          <div className="form-group">
            <button className="btn-default btn-large" onClick={showModal}>
              Show Modal
            </button>
            <button className="btn-default btn-large" onClick={showSignUpModal}>
              Show Modal
            </button>
          </div>
          <br />
          <br />
        </div>

        <FooterFour />
      </main>

      <Modal isOpen={isModalOpen} title="Modal Title" onClose={closeModal}>
        <SignUpForm />
      </Modal>
      <Modal
        isOpen={isSignUpModalOpen}
        title="Modal Title"
        onClose={closeSignUpModal}
      >
        <div className="container" style={{ padding: "60px" }}>
          <div className="row">
            <div className="col-lg-5">
              <img
                className="radius-small"
                src="./images/banner/big_q.png"
                alt="Microlearing Lifeskills Traning"
              ></img>
            </div>

            <div className="col-lg-7">
              <SectionTitle
                textAlign="text-left"
                radiusRounded=""
                title="Updates Reserch and Specail Offers From LearningQ"
              />
              <Mailchimp
                action="https://learningq.us14.list-manage.com/subscribe/post?u=499791b8aba3c5ec4e2a1b86d&amp;id=953f57fa34"
                fields={[
                  {
                    name: "MERGE1",
                    placeholder: "First Name",
                    type: "text",
                    required: true,
                  },
                  {
                    name: "MERGE2",
                    placeholder: "Last Name",
                    type: "text",
                    required: true,
                  },
                  {
                    name: "EMAIL",
                    placeholder: "Email",
                    type: "email",
                    required: true,
                  },
                ]}
                className="mailchimp"
                buttonClassName="btn-default btn-large"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Test;
