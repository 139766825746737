import React from "react";
import SEO from "../../common/SEO";
import HeaderGrant from "../../common/header/HeaderGrant";
import FooterFour from "../../common/footer/FooterThreeAlt";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import PortfolioClasses from "../../elements/portfolio/PortfolioClasses";
import TestimonialThree from "../../elements/testimonial/TestimonialThree";

const Classes = () => {
  return (
    <>
      <SEO title="Online Certification Classes" />
      <main className="page-wrapper">
        <HeaderGrant
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-550 ">
          <div className="container">
            <div className="row">
              <div className="order-1 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-left">
                  {" "}
                  <img
                    className="img-fluid"
                    src="./images/classes/certificate.png"
                    alt="online certificate"
                  />
                </div>
              </div>
              <div className="order-2 order-lg-1 col-lg-5 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-left">
                  <h1
                    className="title theme-gradient"
                    style={{ fontSize: "60px", lineHeight: "70px" }}
                  >
                    Online Certificate Progam
                  </h1>
                  <p className="mb--10">
                    LearningQ and Aspiro are teaming up to offer virtual courses
                    through our platform. These courses feature expert guidance
                    from Aspiro's instructors, and are available to anyone with
                    internet access and a desire to learn. The courses will meet
                    once a week for eight weeks and are open to all.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Slider Area  */}
        <div
          className=" bg_image bg_image_fixed rn-section-gap-sml"
          data-black-overlay="5"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <TestimonialThree teamStyle="" />
        </div>
        {/* Start Portfolio Area  */}
        <div className="rwt-portfolio-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Project"
                  title=""
                  description="Here is a current listing of the virtual courses that are currently accepting registration."
                />
              </div>
            </div>
            <PortfolioClasses Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
          </div>
        </div>
        {/* End Portfolio Area  */}
        <div className="rwt-elements-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title=""
                />
              </div>
            </div>
          </div>
        </div>

        <FooterFour />
      </main>
    </>
  );
};
export default Classes;
