import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    

                    <div className="col-lg-12 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title mt--10 theme-gradient">Learning, but more accessible.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>A micro-learning approach to building life-skills brings learning closer to the point of use. This makes the transfer of knowledge into real world applications more effective.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> 2-5 minute interactive skill units with built in testing.</li>
                                        <li><span className="icon"><FiCheck /></span> Breakdown complex concepts into manageable and repeatable chunks.</li>
                                        <li><span className="icon"><FiCheck /></span> Effective, high-quality training with realtime tracking and recommendations.</li>
                                        <li><span className="icon"><FiCheck /></span> Intuitive approach to planning allows learners quick access to relevant content.</li>
                                        <li><span className="icon"><FiCheck /></span> Maintain consistency with a learner dealing with multiple staff members.</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="/subscribe">Get started <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
