import React, { useState} from "react";
import WhitePaperData from "../../data/portfolio/WhitePaperData.json";
import PortfolioItem from "./PortfolioPaper";


const alldata = WhitePaperData;
const PortfolioTwo = ({ Column }) => {
    const [getAllItems] = useState(alldata);

    
    return (
        <>
            
            <div className="row row--15">
                {getAllItems.map((item) => (
                    <div key={item.id} className={Column}>
                        <PortfolioItem portfolio={item} />
                    </div>
                ))}
            </div>
        </>
    )
}

export default PortfolioTwo;
