import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const TabWFB = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--0  rn-default-tab style-two">
              <div className="col-lg-9">
                <div className="tab-content-panel">
                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-8">
                            <img
                              src="./images/tab/softskills.png"
                              alt="soft skills"
                            />
                          </div>
                          <div className="col-lg-4 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Teamwork</li>
                                        <li>Decision Making</li>
                                        <li>Being Flexible</li>
                                        <li>Setting Professional Boundaries</li>
                                        <li>Dealing with Conflict</li>
                                        <li>Communicating Effectively</li>
                                        <li>Problem Solving</li>

                                        <li>Attitude</li>
                                        <li>
                                          Building and Keeping a Work Schedule
                                        </li>
                                        <li>Active Listening</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-8">
                            <img
                              src="./images/tab/gettingready.png"
                              alt="getting ready to wotk"
                            />
                          </div>
                          <div className="col-lg-4 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Why We Work</li>
                                        <li>On-boarding</li>
                                        <li>Hygiene Expectations</li>{" "}
                                        <li>Appropriate Behavior at Work</li>{" "}
                                        <li>What to Wear to Work</li>{" "}
                                        <li>Accommodations at Work</li>{" "}
                                        <li>Building your Skills</li>
                                        <li>Building Healthy Habits</li>
                                        <li>
                                          Building and Keeping a Work Schedule
                                        </li>
                                        <li>What's Expected From a Job</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-8">
                            <img src="./images/tab/safety.png" alt="saftey" />
                          </div>
                          <div className="col-lg-4 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>What to Do in an Emergency</li>
                                        <li>Understanding the Risks at Work</li>
                                        <li>Cell Phone Rules at Work </li>
                                        <li>Accommodations at Work</li>
                                        <li>
                                          Who and How to Ask for Help at Work
                                        </li>
                                        <li>Dealing with Conflict</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-8">
                            <img
                              src="./images/tab/advance.png"
                              alt="Advancing Your Career"
                            />
                          </div>
                          <div className="col-lg-4 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Performance Reviews </li>
                                        <li>Navigating Your Reputation </li>
                                        <li>Building a Network at Work </li>
                                        <li>Accommodations at Work</li>
                                        <li>Goal Setting and Your Career </li>
                                        <li> Knowing Your Responsibilities </li>
                                        <li>Negotiating a Job Offer</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-8">
                            <img
                              src="./images/tab/howtowork.png"
                              alt="How to work"
                            />
                          </div>
                          <div className="col-lg-4 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Active Listening</li>
                                        <li>Setting Professional Boundaries</li>
                                        <li>
                                          Dealing with Conflict Communicating
                                          Effectively
                                        </li>
                                        <li>
                                          Building and Keeping a Work Schedule
                                        </li>
                                        <li>Attitude</li>
                                        <li>Developing New Skills</li>
                                        <li>Challenges at Work</li>
                                        <li>
                                          Who and How to Ask for Help at Work
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-8">
                            <img
                              src="./images/tab/gettingaround.png"
                              alt="Getting Around"
                            />
                          </div>
                          <div className="col-lg-4 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Active Listening</li>
                                        <li>Taking the Bus</li>
                                        <li>Taking the Train or Subway </li>
                                        <li>Using a Car Service or Taxi </li>
                                        <li>
                                          Building and Keeping a Work Schedule{" "}
                                        </li>
                                        <li>Accommodations at Work</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
              <div className="col-lg-3 no-padding">
                <div className="tab-button-panel">
                  <TabList className="tab-button wfcontent">
                    <Tab>
                      <div className="rn-tab-button">
                        <button>Soft Skills</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="rn-tab-button">
                        <button>Getting Ready for Work</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="rn-tab-button">
                        <button>Safety on the Job</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="rn-tab-button">
                        <button>Advancing Your Career</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="rn-tab-button">
                        <button>How to Work</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="rn-tab-button">
                        <button>Getting Around</button>
                      </div>
                    </Tab>
                  </TabList>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabWFB;
