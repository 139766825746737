import React, { useState } from "react";
import Modal from "../../elements/modal/Modal";
import SignUpForm from "../../elements/forms/GrantSignUp";

const callToActionData = {
  title: "Ready to get started?",
  subtitle: "Let's find the best fit for your needs.",
  btnText: "Let's Chat",
};

const CalltoActionContent = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  function showModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }
  return (
    <div
      className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
      }}
      data-black-overlay="3"
    >
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-10 offset-lg-1">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title mb--0">{callToActionData.title}</h2>
                <p>
                  We'd like to get onboard with us so you can participate with
                  us in this exciting opportunity
                </p>
                <div className="call-to-btn text-center">
                  <button className="btn-default btn-large" onClick={showModal}>
                    Let's Go!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} title="Grant Sign Up" onClose={closeModal}>
        <SignUpForm />
      </Modal>
    </div>
  );
};
export default CalltoActionContent;
