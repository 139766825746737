import React from 'react';
import ReactPlayer from "react-player"

const PortfolioItem = ({ portfolio }) => {
    return (
        <div className="rwt-card">
            <div className="inner">
                <div className="thumbnail">
                    <figure className="card-image">
                      		<div className='player-wrapper'>
        						<ReactPlayer className='react-player' url={portfolio.url} width='100%' height='100%' controls={true} />
							</div>
                    </figure>
                    
                </div>
                <div className="content">
                    <h5 className="title mb--10">
                       
                            {portfolio.title}
                       
                    </h5>
                    <span className="subtitle b2 text-capitalize">{portfolio.category}</span>
                </div>
            </div>
        </div>
    )
}
export default PortfolioItem;