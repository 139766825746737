import React from "react";
import SEO from "../../common/SEO";
import ReactPlayer from "react-player";
import HeaderGrant from "../../common/header/HeaderGrant";
import FooterFour from "../../common/footer/FooterThreeAlt";
import TabHCB from "../../elements/tab/TabHCB";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import { FiArrowRight } from "react-icons/fi";
import AccordionWFB from "../../elements/accordion/AccordionWFB";
import { Link } from "react-router-dom";
import Portfolio from "../../elements/portfolio/Portfolio";
import CalltoActionContent from "../../elements/calltoaction/CalltoActionContent";

class ResponsivePlayer extends ReactPlayer {
  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/845385054"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    );
  }
}
const HCB = () => {

  return (
    <>
      <SEO title="LearningQ | Healthy Cooking Basics Grant Information " />
      <main className="page-wrapper">
        <HeaderGrant btnStyle="btn-small" HeaderSTyle="header-transparent" />
        {/* Start Slider Area  */}
        <div>
          <div
            className="container bg_image bg_image_fixed"
            data-black-overlay="4"
            style={{
              maxWidth: "100%",
              padding: "0",
              margin: "0",
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
            }}
          >
            <div className="row row--30 align-items-center">
              <div className="order-1 order-lg-1 col-lg-8">
                <div className="thumbnail">
                  <ResponsivePlayer />
                </div>
              </div>
              <div className="order-2 order-lg-2 col-lg-4 mt_md--50 mt_sm--50 mt_lg--30">
                <div
                  className="inner text-left"
                  style={{ padding: "60px 20px 20px 20px" }}
                >
                  <h1
                    className="title theme-gradient"
                    style={{ fontSize: "69px" }}
                  >
                    We've got a better way to learn
                  </h1>
                  <p
                    className="description"
                    style={{ fontSize: "15px", lineHeight: "19px" }}
                  >
                    We break complex concepts into 2-5 minute, video based skill
                    units unlocking better results for Learners, Instructors,
                    and Organizations
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                    <Link
                      className="btn-default btn-large"
                      to="/grant/classes"
                    >
                      Browse Virtual Classes{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row mb--35">
              <div className="col-lg-12 text-center">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="What's in Healthy Cooking Basics"
                  description="Our healthy cooking basics covers topics from safely using sharp knives to weekly menu planning. We give you great ways to cook a variety of recipes and safely use appliances."
                />
              </div>

            </div>
            <TabHCB
              serviceStyle="service__style--1 icon-circle-style "
              textAlign="text-center"
            />
            <div className="row" style={{ marginTop: "30px" }}>
              <div className="col-lg-12">
                <a className="btn-default" href="/healthycookingbasics.pdf">
                  Download Certification Info
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />
        <div
          className="height-650  bg_image bg_image_fixed"
          data-white-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <Portfolio />
          </div>
        </div>
        {/* Start Service Area  */}
        <div className="rn-about-area about-style-3 rn-section-gap">
          <div className="container">
            <div className="row row--30">
              <div className="col-lg-11 mt_md--40 mt_sm--40">
                <div className="inner">
                  <div className="section-title">
                    <h2 className="title mb--40">Frequent Questions</h2>
                  </div>
                  <AccordionWFB customStyle="" />
                </div>
              </div>
            </div>
            <div className="rn-section-gap">
              <div className="col-12 align-items-center">
                <Link
                  style={{ margin: "0 auto" }}
                  className="btn-default"
                  to="/grant/Study"
                >
                  Read Program Specifics
                </Link>
              </div>
            </div>
          </div>
        </div>
        <CalltoActionContent />
        <FooterFour />
      </main>
    </>
  );
};

export default HCB;
