import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const TabHCB = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--0  rn-default-tab style-two">
              <div className="col-lg-9">
                <div className="tab-content-panel">
                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-7">
                            <img
                              src="./images/tab/healthyeating.jpg"
                              alt="Healthy Eating"
                            />
                          </div>
                          <div className="col-lg-5 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Meal Planning </li>
                                        <li>Food Safety- Storage</li>
                                        <li>Food Safety- Contamination </li>
                                        <li>Finding a Recipe </li>
                                        <li>Making Healthy Choices </li>
                                        <li>Snacks vs. Meals </li>
                                        <li>
                                          Understanding Nutritional Labels
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-7">
                            <img
                              src="./images/tab/howtocook.jpg"
                              alt="show to cook"
                            />
                          </div>
                          <div className="col-lg-5 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Using Sharp Knives </li>
                                        <li> Using an Oven </li>
                                        <li>Using A Stove </li>

                                        <li>Using a Microwave </li>
                                        <li>Using a Toaster </li>
                                        <li>Using a Blender</li>
                                        <li>Using a Crock Pot</li>
                                        <li>Finding a Recipe</li>
                                        <li>Cooking Pasta</li>
                                        <li>Sautéing Vegetables</li>
                                        <li>Browning Ground Beef</li>
                                        <li>Cooking Chicken</li>
                                        <li>Cooking From a Recipe</li>
                                        <li>Understanding Expiration Dates</li>
                                        <li>Food Safety - Storage </li>
                                        <li>Food Safety - Contamination </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-7">
                            <img
                              src="./images/tab/quickrecipies.jpg"
                              alt="Quick Recipies"
                            />
                          </div>
                          <div className="col-lg-5 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Scrambled Eggs</li>
                                        <li>Cooking Pasta</li>
                                        <li>Cooking Vegetables</li>
                                        <li>Making Tacos</li>
                                        <li>Spaghetti With Sauce </li>
                                        <li>Cooking Chili </li>
                                        <li>Making Stir Fry</li>
                                        <li>Packaged Frozen Meals </li>
                                        <li>Preparing a Salad </li>
                                        <li>Mediterranean Chicken </li>
                                        <li>Let's Go With Burgers </li>
                                        <li>Easy Indian Dinner</li>
                                        <li>Cooking Chicken </li>
                                        <li>Grilled Cheese</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-7">
                            <img
                              src="./images/tab/groceryshopping.jpg"
                              alt="Grocery Shopping"
                            />
                          </div>
                          <div className="col-lg-5 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Finding a Recipe</li>
                                        <li>Meal Planning</li>
                                        <li>
                                          Understanding Nutritional Labels
                                        </li>
                                        <li>Food Safety - Storage</li>
                                        <li>Budgeting</li>
                                        <li>Grocery Lists</li>
                                        <li>Understanding Expiration Dates</li>
                                        <li>Checkout</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="rn-tab-content">
                      <div className="inner">
                        <div className="row align-items-center row--30">
                          <div className="col-lg-7">
                            <img
                              src="./images/tab/kitchensafety.jpg"
                              alt="Kitchen Safety"
                            />
                          </div>
                          <div className="col-lg-5 mt_md--30 mt_sm--30">
                            <div className="row row--15 mt_dec--30 service-wrapper">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="card-box card-style-1 text-left">
                                  <div className="inner">
                                    <div className="content">
                                      <h4 className="title mb--20">
                                        Skill Units
                                      </h4>
                                      <ul>
                                        <li>Cleaning a Kitchen</li>
                                        <li>Sharp Knives</li>
                                        <li>Kitchen Emergencies - Fire</li>
                                        <li>Food Safety- Storage</li>
                                        <li>Food Safety- Contamination</li>
                                        <li>Electricity in the Kitchen</li>
                                        <li>What To Do When You Smell Gas</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
              <div className="col-lg-3 no-padding">
                <div className="tab-button-panel">
                  <TabList className="tab-button wfcontent">
                    <Tab>
                      <div className="rn-tab-button">
                        <button>Healthy Eating</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="rn-tab-button">
                        <button>How to Cook</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="rn-tab-button">
                        <button>Quick Recipies</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="rn-tab-button">
                        <button>Grocery Shopping</button>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="rn-tab-button">
                        <button>Kitchen Safety</button>
                      </div>
                    </Tab>
                  </TabList>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabHCB;
