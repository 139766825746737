import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FiActivity, FiMap, FiRefreshCcw } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiRefreshCcw />,
    title: "Pre-Teach and Practice",
    description:
      "Highly engaging skills training in 60-90 seconds, available anytime, anywhere.",
  },
  {
    icon: <FiActivity />,
    title: "Live and Virtual Courses",
    description:
      "Create, manage, and customize content for small or large groups.",
  },
  {
    icon: <FiMap />,
    title: "One-On-One Coaching",
    description:
      "Build a person-centered plan to improve skills and achieve goals.",
  },
];
const ServiceThree = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4 className="title w-600">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceThree;
