import React from 'react';
import SEO from "../../common/SEO";
import FooterThreeAlt from '../../common/footer/FooterThreeAlt';
import SectionTitle from "../sectionTitle/SectionTitle";
import SubscribeOne from "./SubscribeOne";
import HeaderTwo from '../../common/header/HeaderTwo';

const Subscribe = () => {

    return (
        <>  
		<SEO title="LeaningQ | Product Sign Up" />
            <main className="page-wrapper">
              
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
               
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        title = "Find out about LearningQ's high-engagement skills training experience."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <SubscribeOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
		     <FooterThreeAlt />
             </main>
            
        </>
    )
}
export default Subscribe;