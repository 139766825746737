import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiFeather, FiFilm, FiUsers} from "react-icons/fi";
import {Link} from "react-router-dom";

const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Planning A Curriculum.",
        description: "The LearningQ writing team will help you create an engaging and straightforward scope for your curriculum product, identifying key micro-learning skills and badges, and bring it to life through highly accessible skills units.",
        icon: <FiFeather />,
        
    },
    {
        id: "2",
        date: "Step-2",
        title: "Produce Engaging Skill Units.",
        description: "Our content production team and education experts will create your scripts, flow, visuals, and narratives for exceptional delivery.",
        icon: <FiFilm />,
        
    },{
        id: "3",
        date: "Step-3",
        title: "Partnership Distribution.",
        description: "We host your content on the LearningQ platform with an array of analytics to give you real-time outcomes and measurements for strategic growth. We can also help you grow your base of users by recommending your content to other learners on the platform.",
        icon: <FiUsers />,
        
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        
                                        <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <Link className="btn-default btn-large round" to="/content"><span>Get Started Now</span></Link>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                     <div className="icon">
                                		{data.icon}
                            		</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
