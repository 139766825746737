import React from "react";
import SEO from "../../common/SEO";

import HeaderGrant from "../../common/header/HeaderGrant";
import FooterFour from "../../common/footer/FooterThreeAlt";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Separator from "../../elements/separator/Separator";
import CalltoActionClasses from "../../elements/calltoaction/CalltoActionClasses";
import AccordionGrantStudent from "../../elements/accordion/AccordionGrantStudent";
import ServiceGrantStudent from "../../elements/service/ServiceGrantStudent";
import Split from "../../elements/split/SlipGrantStudent";
import { Link } from "react-router-dom";

const Student = () => {
  return (
    <>
      <SEO title="LearningQ | Student Grant Information" />
      <main className="page-wrapper">
        <HeaderGrant btnStyle="btn-small" HeaderSTyle="header-transparent" />
        {/* Start Slider Area  */}
        <div>
          <div
            className="container"
            style={{ maxWidth: "100%", padding: "0", margin: "0" }}
          >
            <Split />
          </div>
        </div>
        {/* End Slider Area  */}
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="We've got good news and we're passing it along"
                  description="LearningQ recently received two Federal grants from the National Institute on Disability, Independent Living, and Rehabilitation Research's (NIDILRR) Small Business Innovation Research program. These grants allow us to provide 600 scholarships to Learners who want to engage with our Healthy Cooking Basics and Workforce Basics. "
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  title="Flexibility, Engagement, Retention"
                  description="We offer an accessible learning platform with access to highly engaging lifeskills development. Take the class with your instrutcor then access the content when you need, where ever you need. "
                />
              </div>
            </div>
            <ServiceGrantStudent
              serviceStyle="service__style--1 icon-circle-style "
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />
        {/* Start Service Area  */}
        <div className="rn-about-area about-style-3 rn-section-gap">
          <div className="container">
            <div className="row row--30">
              <div className="col-lg-11 mt_md--40 mt_sm--40">
                <div className="inner">
                  <div className="section-title">
                    <h2 className="title mb--40">Frequent Questions</h2>
                  </div>
                  <AccordionGrantStudent customStyle="" />
                </div>
              </div>
            </div>
            <div className="rn-section-gap">
              <div className="col-12 align-items-center">
                <Link
                  style={{ margin: "0 auto" }}
                  className="btn-default"
                  to="/grant/Study"
                >
                  Read Program Specifics
                </Link>
              </div>
            </div>
          </div>
        </div>
        <CalltoActionClasses />
        <FooterFour />
      </main>
    </>
  );
};

export default Student;
