import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const Data = [
  {
    countNum: 24,
    countTitle: "Increase in Skills Awareness",
  },
  {
    countNum: 83,
    countTitle: "Curriculum Completion",
  },
  {
    countNum: 97,
    countTitle: "Sucessful Skill Completion",
  },
];
const CounterUpThree = ({ textALign, counterStyle, column }) => {
  return (
    <div className="row">
      {Data.map((data, index) => (
        <div className={`${column}`} key={index}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`count-box ${counterStyle} ${textALign}`}>
              <TrackVisibility once>
                {({ isVisible }) =>
                  isVisible && (
                    <div className="count-number">
                      {isVisible ? <CountUp end={data.countNum} /> : 0}%
                    </div>
                  )
                }
              </TrackVisibility>
              <h5 className="title">{data.countTitle}</h5>
              <p className="description">{data.description}</p>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default CounterUpThree;
