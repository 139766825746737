import React from "react";
import { Link } from "react-router-dom";

const PortfolioHowItem = ({ portfolio }) => {
  return (
    <div className="rwt-card">
      <div className="inner">
        <div className="thumbnail">
          <figure className="card-image">
            <Link
              to={process.env.PUBLIC_URL + `/getting-started/${portfolio.id}`}
            >
              <img
                className="img-fluid"
                src={portfolio.image}
                alt={portfolio.title}
              />
            </Link>
          </figure>
          <Link
            to={process.env.PUBLIC_URL + `/getting-started/${portfolio.id}`}
            className="rwt-overlay"
          ></Link>
        </div>
        <div className="content">
          <h5 className="title mb--10">
            <Link
              to={process.env.PUBLIC_URL + `/getting-started/${portfolio.id}`}
            >
              {portfolio.title}
            </Link>
          </h5>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: portfolio.shortDescription }}
          ></div>{" "}
          <Link
            to={process.env.PUBLIC_URL + `/getting-started/${portfolio.id}`}
          >
            See how to get started
          </Link>
        </div>
      </div>
    </div>
  );
};
export default PortfolioHowItem;
