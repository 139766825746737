import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <Link to="/">Home</Link>
      </li>
      <li className="has-droupdown">
        <a href="https://store.learningq.org/" target='_self'>Products</a>
        <ul className="submenu">
          <li>
          <a href="https://store.learningq.org/product-page/job-of-getting-a-job" target="_self">
              The Job of Getting a Job
              </a>
          </li>
          <li> 
            <a href="https://store.learningq.org/product-page/career-foundations" target="_self">
              Career Foundations
            </a>
          </li>
          <li>
          <a href="https://store.learningq.org/product-page/workforce-basics" target="_self">Workforce Basics</a>
          </li>
          <li>
          <a href="https://store.learningq.org/product-page/healthy-cooking-basics" target="_self">Healthy Cooking Basics</a>
          </li>
          <li>
          <a href="https://store.learningq.org/product-page/safety-and-police-encounters" target="_self">Safety and Police Encounters</a>
          </li>
        </ul>
      </li>
      <li className="has-droupdown">
        <Link to="/gettingstarted">Getting Started</Link>
        <ul className="submenu">
          <li>
            <Link to="/getting-started/1">Live Groups</Link>
          </li>
          <li>
            <Link to="/getting-started/2">Virtual Courses</Link>
          </li>
          <li>
            <Link to="/getting-started/3">Independent Study</Link>
          </li>
          <li>
            <Link to="/getting-started/4">One-On-One</Link>
          </li>
          <li>
            <Link to="/getting-started/5">Waitlist Engagement</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/about-us">About Us</Link>
      </li>
      <li>
        <Link to="/content-creation">Content Creation</Link>
      </li>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  );
};
export default Nav;
