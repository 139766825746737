import React from "react";

const BrandList = [
  {
    image: "./images/brand/hhs.png",
  },
  {
    image: "./images/brand/aspiro.png",
  },
  {
    image: "./images/brand/ACL.gif",
  },
  {
    image: "./images/brand/nbI.png",
  },
  {
    image: "./images/brand/MU.png",
  },
  {
    image: "./images/brand/compass.png",
  },
  {
    image: "./images/brand/nidilrr.png",
  },
];

const BrandThree = ({ brandStyle }) => {
  return (
    <ul className={`brand-list ${brandStyle}`}>
      {BrandList.map((data, index) => (
        <li key={index} style={{ padding: "32px" }}>
          <img src={`${data.image}`} alt="" />
        </li>
      ))}
    </ul>
  );
};

export default BrandThree;
