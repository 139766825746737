import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionGrantStudent = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Who is eligbile to participate?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            We are seeking learners who are participating in existing
            programming through an agency. The programming can be done either
            in-person or virtually, but the LearningQ curricula are designed to
            supplement more robust lesson frameworks.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            What are the expectations for learners?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            Upon signing into LearningQ, you will be asked to take a brief
            assessment on the topics which will be covered by the curriculum.
            After completing your assigned courses, you'll be prompted to take
            another assessment, along with a short survey. We want your honest
            feedback on the content you used and to understand if it was
            beneficial to you.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            What are the requirements to use LearningQ?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            For each participant, we need either a unique email or cell phone
            number (with text capabilities) so we can send you a registration
            link. As this training is all conducted via the internet, you will
            also need access to a computer, tablet or cell phone to watch the
            videos and take the quizzes.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            What do I get in return for helping?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            As a participant in our research program, you will be given access
            to your LearningQ account and content through August, 2024. If you
            want to refresh a skill, or watch a video on how to chop vegetables
            before you make your first stir fry, you'll have access to our
            content to be able to watch as often as you would like.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionGrantStudent;
