import React from "react";
import IframeResizer from "iframe-resizer-react";

const MainSignUp = () => {
  return (
    <IframeResizer
      log
      bodyMargin="0px"
      bodyPadding="0px"
      widthCalculationMethod="max"
      sizeWidth={true}
      style={{
        width: "100%",
        height: "1000px",
        maxWidth: "1320px",
        margin: "0 auto",
        overflow: "auto",
        border: "none",
      }}
      title="Grant Partner Overview"
      src="https://forms.monday.com/forms/embed/5de2a6b7c92316f60055e4b298242561"
    ></IframeResizer>
  );
};

export default MainSignUp;
