import React from "react";
import ReactPlayer from "react-player";
import SEO from "../common/SEO";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterThree from "../common/footer/FooterThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TabOne from "../elements/tab/TabOne";
import Portfolio from "../elements/portfolio/Portfolio";
import AboutThree from "../elements/about/AboutThree";
import TimelineOne from "../elements/portfolio/PortfolioThreeColumn";
import HeaderTopNews from "../common/header/HeaderTopNews";
import Modal from "../elements/modal/Modal";
import Brand from "../elements/brand/Brand";
import DemoSignUp from "../elements/forms/DemoSignUp";
import { useState } from "react";

class ResponsivePlayer extends ReactPlayer {
  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/805158023"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    );
  }
}

const Home = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  function showModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }
  return (
    <>
      <SEO title="LearningQ | Home | Microlearning and Lifeskills Development" />
      <main className="page-wrapper">
       
        <HeaderTwo btnStyle="btn-small" />
        {/* Start Slider Area  */}
        <div>
          <div
            className="container"
            style={{ maxWidth: "100%", padding: "0", margin: "0" }}
          >
            <div className="row row--30 align-items-center">
              <div className="order-1 order-lg-1 col-lg-8">
                <div className="thumbnail">
                  <ResponsivePlayer />
                </div>
              </div>
              <div className="order-2 order-lg-2 col-lg-4 mt_md--50 mt_sm--50 mt_lg--30">
                <div
                  className="inner text-left"
                  style={{ padding: "60px 20px 20px 20px" }}
                >
                  <h1
                    className="title theme-gradient"
                    style={{ fontSize: "69px" }}
                  >
                    We've got a better way to learn
                  </h1>
                  <p
                    className="description"
                    style={{ fontSize: "15px", lineHeight: "19px" }}
                  >
                    We break complex concepts into 2-5 minute, video based skill
                    units unlocking better results for Learners, Instructors,
                    and Organizations
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                    <button className="btn-default" onClick={showModal}>
                      Reqest a Demo{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <HeaderTopNews />
        <div
          className="slider-area slider-style-3 height-550  bg_image bg_image_fixed"
          data-black-overlay="2"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title" style={{ color: "#EE6C4D" }}>
                    The human brain is not wired to maintain focus for hours on
                    end. There is a better way to build and reinforce new skills
                    and concepts.
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  LearningQ helps learners take one step at a time so that they
                  clearly understand ideas before moving to the next one. Our
                  content is built to break new concepts down to their smallest
                  steps and let learners advance at their own pace. This allows
                  them to advance, revisit, and adjust their content to stay
                  fully engaged and build skills effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" bg_image bg_image_fixed rn-section-gap"
          data-white-overlay="6"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <h3 className="title text-center" style={{ color: "#FFF" }}>
              Partners
            </h3>
            <Brand />
          </div>
        </div>
        <div className="container">
          <Portfolio />
        </div>

        {/* Start Elements Area  */}
        <div
          className=" rwt-tab-area  bg_image bg_image_fixed"
          data-white-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
          }}
        >
          <div className="container">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                title="The Benefits of a Flexible Approach"
              />
            </div>
            <TabOne />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* End Service Area  */}
        <AboutThree />
        <HeaderTopNews />
        <TimelineOne />
        <FooterThree />
      </main>
      <Modal isOpen={isModalOpen} title="Grant Sign Up" onClose={closeModal}>
        <DemoSignUp></DemoSignUp>
      </Modal>
    </>
  );
};
export default Home;
