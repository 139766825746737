import React from "react";
import SEO from "../../common/SEO";
import HeaderGrant from "../../common/header/HeaderPartner";
import FooterThreeAlt from "../../common/footer/FooterThreeAlt";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import Typed from "react-typed";
import TestimonialThree from "../../elements/testimonial/TestimonialThree";
import CountUpThree from "../../elements/counterup/CounterUpThree";
import PricingThree from "../../elements/pricing/PricingFive";
import ServiceOne from "../../elements/service/ServiceThree";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionFive";
import TimelineGrantPartner from "../../elements/timeline/TimelinePartner";
import ReactPlayer from "react-player";

class ResponsivePlayer extends ReactPlayer {
  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/788376200"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    );
  }
}
class IntroPlayer extends ReactPlayer {
  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/805158023"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    );
  }
}

const abilitytech = () => {
  return (
    <>
      <SEO title="LearningQ | 412AbiltiyTech" />
      <main className="page-wrapper">
        <HeaderGrant btnStyle="btn-small" HeaderSTyle="header-transparent" />
        <div className="main-content">
          <div className="slider-area height-550  ">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="thumbnail">
                    <IntroPlayer />
                  </div>
                </div>
                <div className="col-lg-5">
                  {" "}
                  <h1 className="title theme-gradient ">
                    Partnering to provide <br />{" "}
                    <Typed
                      strings={["Engagement.", "Outreach.", "Opportunity."]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loopCount={1}
                    />
                  </h1>
                  <p className="mb--10">
                    We are delighted to announce the new partnership program
                    between LearningQ and 412AbilityTech! We are expanding the
                    ecosystem for 412AbilityTech members.
                  </p>
                  <p className="mb--10">
                    This collaboration provides members-only incentives and
                    support to LearningQ tools. 412AbilityTech members can
                    broaden their existing services and address the needs of
                    individuals on your waiting lists with our flexible learning
                    system.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Start Elements Area  */}
          <div className="rn-service-area rn-section-gap-sml">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="What we can do for you"
                    title="Flexibility to fit your needs"
                    description="LearningQ's short, easily repeatable lessons can enhance existing programs <br/> or help reach underserved audiences."
                  />
                </div>
              </div>
              <ServiceOne
                serviceStyle="service__style--1 bg-color-blackest radius mt--25 rbt-border"
                textAlign="text-center"
              />
            </div>
          </div>

          <div className=" bg_image bg_image_fixed rn-section-gap-sml">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content text-center">
                    <div className="thumbnail">
                      <ResponsivePlayer />
                    </div>
                    <h5 className="title" style={{ color: "#EE6C4D" }}>
                      Sample Content - Interview Questions
                    </h5>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3 className="title" style={{ color: "#EE6C4D" }}>
                    Better Content, Better Results
                  </h3>
                  <p className="mb--10">
                    All of our workforce content is developed to meet CARF
                    accreditation standards, written by professionals in special
                    education, and reviewed by disability and accessibility
                    experts. We constantly test and improve our lessons to
                    ensure maximum effectiveness.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="rwt-counterup-area rn-section-gap-sml">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle=""
                    title="Numbers That Say a Thousand Words"
                    description="Here are some of the results from active participants with IDD in our recent two-month national study."
                  />
                </div>
              </div>
              <CountUpThree
                column="col-lg-4 col-md-6 col-sm-6 col-12"
                counterStyle="counter-style-2 border-style color-variation"
                textALign="text-center"
              />
            </div>
          </div>
          <div
            className=" bg_image bg_image_fixed rn-section-gap-sml"
            data-black-overlay="1"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
            }}
          >
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12 text-center section-title">
                  <h1 className="title theme-gradient ">
                    Get in on a great deal
                  </h1>
                </div>
              </div>

              <CalltoActionSeven />
              <div className="container">
                <PricingThree />
              </div>
            </div>
          </div>
          {/* End Elements Area  */}

          <div
            className=" bg_image bg_image_fixed rn-section-gap-sml"
            data-black-overlay="5"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`,
            }}
          >
            <div className="container">
              <TestimonialThree teamStyle="" />
            </div>
          </div>
          <div className="rn-section-gap-sml">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle=""
                    title="Starting a course is effortless."
                    description="LearningQ is with you every step of the way with this simple four-step process."
                  />
                </div>
              </div>
              <TimelineGrantPartner />
            </div>
          </div>
        </div>
      </main>
      <FooterThreeAlt />
    </>
  );
};
export default abilitytech;
