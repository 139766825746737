import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    What is a Skill Unit ? How does it work?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>A skill unit is a 2-5 minute video lesson combined with a short interactive skill test. They can be combined to create an understanding of complex concepts while letting learners proceed at their own pace.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Can I learn complex life skills in 2-5 minutes?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Skill units can be combined in what we call LearningQs to teach concepts of varying complexity one step at a time. This approach lets learners build comprehension at their own pace and also allows people to review specific skills closer to the time and location of use to help improve retention.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    How much time do I need to dedicate to use LearningQ?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>LearningQ has been designed to be both flexible and intuitive. It takes learners and instructors about 15 minutes to become proficient with the platform and start working on relevant content. After that, LearningQ can be used in flexible blocks to learn new concepts, and on-demand to reinforce concepts when needed.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    How can I use LearningQ to add to my existing life skills training?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Our robust life skills curricilum can be used on their own to build certification programs, or can be flexibly added to augment your existing content. LearningQ can also help organizations develop their own interactive skills-based content.</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

