import React from "react";
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
  {
    id: "01",
    form: "Instructor",
    description:
      "It's a must for anyone seeking employment! Offering accessible and visually engaging learning experiences, these courses not only enhance skills, but also empower individuals to navigate the professional world with newfound independence.",
    name: "Sarah May",
    subtitle: "Workforce Basics",
    image: "testimonial-dark-01",
  },
  {
    form: "Instructor",
    description:
      "I thought it was a very beneficial course for our population. The fact that they had the responsibility to log on and get things ready for themselves was huge. I think holding them accountable for this was such an important part of their learning.",
    name: "Nina Sarokin",
    subtitle: "Healthy Cooking Basics",
    image: "testimonial-dark-02",
  },
  {
    form: "Participant",
    description:
      "The course moved forward easily, and I liked how interesting the videos were. I'm excited about getting a job.",
    name: "Mike M",
    subtitle: "Workforce Basics",
    image: "testimonial-dark-02",
  },
];
const TestimonialThree = ({ teamStyle }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <Slider
          className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60"
          {...slickDot}
        >
          {TestimonialData.map((data, index) => (
            <div key={index} className={`testimonial-style-two ${teamStyle}`}>
              <div className="row align-items-center row--20">
                <div className="order-md-1 col-lg-6 offset-lg-3">
                  <div className="content mt_sm--40">
                    <span className="form">{data.form}</span>
                    <p className="description">{data.description}</p>
                    <div className="client-info">
                      <h4 className="title">{data.name}</h4>
                      <h6 className="subtitle">{data.subtitle}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialThree;
