import React from 'react';
import { FiActivity, FiCast, FiMap, FiRefreshCcw } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
	    {
       icon: <FiActivity />,
        title: 'Accessible Learning Platform',
        description: 'The LearningQ platform offers the best way to help learners engage and retain life skills.'
    },
	  {
       icon: <FiRefreshCcw />,
        title: 'Engaging Content',
        description: 'LearningQ along with our content parters offer high demand life skills certifications, in areas like workforce readiness, wellness and independent living.'
    },
    {
       icon: <FiCast />,
        title: 'Curriculum Design & Development',
        description: 'Our highly trained content team can help you develop and produce your own unique content and bring it online.'
    },
    {
        icon: <FiMap />,
        title: 'Co-op Sales',
        description: 'LearningQ can help you find new audiences by cross marketing your content to our existing network.'
    }
    
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;