import React from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderThree";
import FooterFour from "../common/footer/FooterThreeAlt";
import Separator from "../elements/separator/Separator";




const rawHTML = `<p><b>Last updated: December 2022</b></p>

<p>
    LearningQ, llc dba LearningQ ("we", "us", and "our") provides a fun,
    interactive way to learn new skills in bite-sized visual sessions. This
    Privacy Policy is designed to help you understand how we collect, use, and
    share your personal information and to help you understand and exercise your
    privacy rights.
</p>

<p><b>1. SCOPE</b></p>
<p>
    This Privacy Policy applies to personal information processed by us,
    including on our websites, mobile applications, and other online or offline
    offerings. To make this Privacy Policy easier to read, our websites, mobile
    applications, and other offerings are collectively called "Services".
</p>

<p><b>2. PERSONAL INFORMATION WE COLLECT</b></p>
<p>
    The categories of personal information we collect depend on how you interact
    with us, our Services, and the requirements of applicable law. We collect
    information that you provide to us, information we obtain automatically when
    you use our Services, and information from other sources such as third-party
    services and organizations, as described below.
</p>

<p><b>A. Information You Provide to Us Directly</b></p>
<p>We may collect the following personal information that you provide to us.</p>

<ul class="list">
    <li>
        <b>Account Creation.</b> We may collect information when you create an account,
        such as your email address.
    </li>
    <li>
        <b>Purchases.</b> We may collect personal information and details associated
        with your purchases, including payment information. Any payments made via
        our Services are processed by third-party payment processors, such as through
        the Apple or Android app store, or through Stripe. We do not directly collect
        or store any payment card information entered through our Services, but it
        may receive information associated with your payment card information (e.g.,
        your billing details).
    </li>
    <li>
        <b>Your Communications with Us.</b> We may collect personal information,
        such as email address, phone number, or mailing address when you request
        information about our Services, request customer or technical support, apply
        for a job, or otherwise communicate with us.
    </li>
    <li>
        <b>Surveys.</b> We may contact you to participate in surveys. If you decide
        to participate, you may be asked to provide certain information which may
        include personal information.
    </li>
    <li>
        <b>Job Applications.</b> We may post job openings and opportunities on our
        Services. If you reply to one of these postings by submitting your application,
        CV and/or cover letter to us, we will collect and use your information to
        assess your qualifications.
    </li>
</ul>

<p><b>B. Information Collected Automatically</b></p>
<p>
    We may collect personal information automatically when you use our Services:
</p>

<ul class="list">
    <li>
        <b>Automatic Data Collection. </b> We may collect certain information automatically
        when you use our Services, such as your Internet protocol (IP) address, user
        settings, MAC address, cookie identifiers, mobile carrier, mobile advertising
        and other unique identifiers, browser or device information, location information
        (including approximate location derived from IP address), and Internet service
        provider. We may also automatically collect information regarding your use
        of our Services, such as pages that you visit before, during and after using
        our Services, information about the links you click, the types of content
        you interact with, the frequency and duration of your activities, and other
        information about how you use our Services.
    </li>
    <li>
        <p>
            <b>Cookies and Other Technologies.</b> We, as well as third parties
            that provide content, advertising, or other functionality on our
            Services, may use cookies, pixel tags, local storage, and other
            technologies ("<b>Technologies</b>") to automatically collect
            information through your use of our Services.
        </p>

        <p>
            <b>Cookies.</b> Cookies are small text files placed in device browsers
            that store preferences and facilitate and enhance your experience.
        </p>

        <p>
            Our uses of these Technologies fall into the following general
            categories:
        </p>

        <ul class="list circle">
            <li>
                <b>Operationally Necessary.</b> This includes Technologies that allow
                you access to our Services, applications, and tools that are required
                to identify irregular website behavior, prevent fraudulent activity
                and improve security or that allow you to make use of our functionality;
            </li>
            <li>
                <b>Performance-Related.</b> We may use Technologies to assess the
                performance of our Services, including as part of our analytic practices
                to help us understand how individuals use our Services (see Analytics
                below);
            </li>
            <li>
                <b>Functionality-Related.</b> We may use Technologies that allow
                us to offer you enhanced functionality when accessing or using our
                Services. This may include identifying you when you sign into our
                Services or keeping track of your specified preferences, interests,
                or past items viewed;
            </li>
            <li>
                <b>Analytics.</b> We may use Technologies and other third-party
                tools to process analytics information on our Services, such as
                Google Analytics. For more information, please visit
                <a
                    href="https://policies.google.com/technologies/partner-sites"
                    class="link"
                    target="_blank"
                    rel="noreferrer">Google Analytics' Privacy Policy</a
                >. To learn more about how to opt-out of Google Analytics use
                of your information, please
                <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    class="link"
                    target="_blank"
                    rel="noreferrer">click here</a
                >.
            </li>
        </ul>

        <p>
            See "<b>Your Privacy Choices and Rights</b>" below to understand
            your choices regarding these Technologies.
        </p>
    </li>
</ul>

<p><b>3. HOW WE USE YOUR INFORMATION</b></p>
<p>
    We use your information for a variety of business purposes, including to
    provide our Services, for administrative purposes, and to market our
    products and Services, as described below.
</p>

<p><b>A. Provide Our Services</b></p>
<p>
    We use your information to fulfil our contract with you and provide you with
    our Services, such as:
</p>

<ul class="list">
    <li>Managing your information and accounts;</li>
    <li>
        Providing access to certain areas, functionalities, and features of our
        Services;
    </li>
    <li>Answering requests for customer or technical support;</li>
    <li>
        Communicating with you about your account, activities on our Services,
        and policy changes;
    </li>
    <li>
        Processing your financial information and other payment methods for
        products or Services purchased; and
    </li>
    <li>
        Processing applications if you apply for a job we post on our Services;
    </li>
</ul>

<p><b>B. Administrative Purposes</b></p>
<p>We use your information for various administrative purposes, such as:</p>

<ul class="list">
    <li>
        Pursuing our legitimate interests such as direct marketing, research and
        development (including marketing research), network and information
        security, and fraud prevention;
    </li>
    <li>
        Detecting security incidents, protecting against malicious, deceptive,
        fraudulent or illegal activity, and prosecuting those responsible for
        that activity;
    </li>
    <li>Measuring interest and engagement in our Services;</li>
    <li>Improving, upgrading or enhancing our Services;</li>
    <li>Ensuring internal quality control and safety;</li>
    <li>
        Authenticating and verifying individual identities, including requests
        to exercise your rights under this policy;
    </li>
    <li>Debugging to identify and repair errors with our Services;</li>
    <li>
        Auditing relating to interactions, transactions and other compliance
        activities;
    </li>
    <li>Enforcing our agreements and policies; and</li>
    <li>Complying with our legal obligations.</li>
</ul>

<p><b>C. Marketing and Advertising our Products and Services</b></p>
<p>
    We may use personal information to tailor and provide you with content. We
    may provide you with these materials as permitted by applicable law.
</p>
<p>
    If you have any questions about our marketing practices or if you would like
    to opt out of the use of your personal information for marketing purposes,
    you may contact us at any time as set forth in "Contact Us" below.
</p>

<p><b>B. Other Purposes</b></p>
<p>
    We also use your information for other purposes as requested by you or as
    permitted by applicable law.
</p>

<ul class="list">
    <li>
        <b>Consent.</b>  We may use personal information for other purposes that
        are clearly disclosed to you at the time you provide personal information
        or with your consent.
    </li>
    <li>
        <b>De-identified and Aggregated Information.</b> We may use personal information
        and other information about you to create de-identified and/or aggregated
        information, such as de-identified demographic information, [de-identified
        location information,] information about the device from which you access
        our Services, or other analyses we create.
    </li>
</ul>

<p><b>4. HOW WE DISCLOSE YOUR INFORMATION</b></p>
<p>
    We disclose your information to third parties for a variety of business
    purposes, including to provide our Services, to protect us or others, or in
    the event of a major business transaction such as a merger, sale, or asset
    transfer, as described below.
</p>

<p><b>A. Disclosures to Provide our Services</b></p>
<p>
    The categories of third parties with whom we may share your information are
    described below.
</p>

<ul class="list">
    <li>
        <b>Service Providers.</b> We may share your personal information with our
        third-party service providers who use that information to help us provide
        our Services. This includes service providers that provide us with IT support,
        hosting, payment processing, customer service, and related services.
    </li>
    <li>
        <b>Business Partners.</b> We may share your personal information with business
        partners to provide you with a product or service you have requested. We
        may also share your personal information to business partners with whom we
        jointly offer products or services.
    </li>
    <li>
        <b>Affiliates.</b> We may share your personal information with our company
        affiliates.
    </li>
    <li>
        <b>APIs/SDKs.</b> We may use third-party Application Program Interfaces ("APIs")
        and Software Development Kits ("SDKs") as part of the functionality of our
        Services. For more information about our use of APIs and SDKs, please contact
        us as set forth in “Contact Us” below.
    </li>
</ul>

<p><b>B. Disclosures to Protect Us or Others</b></p>
<p>
    We may access, preserve, and disclose any information we store associated
    with you to external parties if we, in good faith, believe doing so is
    required or appropriate to: comply with law enforcement or national security
    requests and legal process, such as a court order or subpoena; protect your,
    our, or others' rights, property, or safety; enforce our policies or
    contracts; collect amounts owed to us; or assist with an investigation or
    prosecution of suspected or actual illegal activity.
</p>

<p>
    <b>C. Disclosure in the Event of Merger, Sale, or Other Asset Transfers</b>
</p>
<p>
    If we are involved in a merger, acquisition, financing due diligence,
    reorganization, bankruptcy, receivership, purchase or sale of assets, or
    transition of service to another provider, your information may be sold or
    transferred as part of such a transaction, as permitted by law and/or
    contract.
</p>

<p><b>5. YOUR PRIVACY CHOICES AND RIGHTS</b></p>
<p>
    <b>Your Privacy Choices.</b> The privacy choices you may have about your personal
    information are determined by applicable law and are described below.
</p>

<ul class="list">
    <li>
        <b>Email Communications.</b> If you receive an unwanted email from us, you
        can use the unsubscribe link found at the bottom of the email to opt out
        of receiving future emails. Note that you will continue to receive transaction-related
        emails regarding products or Services you have requested.
    </li>
    <li>
        <b>"Do Not Track".</b> Do Not Track ("DNT") is a privacy preference that
        users can set in certain web browsers. Please note that we do not respond
        to or honor DNT signals or similar mechanisms transmitted by web browsers.
    </li>
    <li>
        <p>
            <b>Cookies.</b> You may stop or restrict the placement of Technologies
            on your device or remove them by adjusting your preferences as your browser
            or device permits. However, if you adjust your preferences, our Services
            may not work properly. Please note that cookie-based opt-outs are not
            effective on mobile applications.
        </p>

        <p>
            Please note you must separately opt out in each browser and on each
            device.
        </p>
    </li>
</ul>

<p>
    <b>Your Privacy Rights.</b> In accordance with applicable law, you may have the
    right to:
</p>
<ul class="list">
    <li>
        <b>Access Personal Information</b> about you, including: (i) confirming whether
        we are processing your personal information; (ii) obtaining access to or
        a copy of your personal information; and (iii) receiving an electronic copy
        of personal information that you have provided to us, or asking us to send
        that information to another company (the "right of data portability");
    </li>
    <li>
        <b>Request Correction</b> of your personal information where it is inaccurate
        or incomplete. In some cases, we may provide self-service tools that enable
        you to update your personal information;
    </li>
    <li><b>Request Deletion</b> of your personal information;</li>
    <li>
        <b>Request Restriction of or Object to</b> our processing of your personal
        information; and
    </li>
    <li>
        <b>Withdraw your Consent</b> to our processing of your personal information.
    </li>
</ul>
<p>
    If you would like to exercise any of these rights, please contact us as set
    forth in the "Contact Us" below. We will process such requests in accordance
    with applicable laws.
</p>

<p><b>6. SECURITY OF YOUR INFORMATION</b></p>
<p>
    We take steps to ensure that your information is treated securely and in
    accordance with this Privacy Policy. Unfortunately, no system is 100%
    secure, and we cannot ensure or warrant the security of any information you
    provide to us. We have taken appropriate safeguards to require that your
    personal information will remain protected and require our third-party
    service providers and partners to have appropriate safeguards as well. To
    the fullest extent permitted by applicable law, we do not accept liability
    for unauthorized disclosure.
</p>
<p>
    By using our Services or providing personal information to us, you agree
    that we may communicate with you electronically regarding security, privacy,
    and administrative issues relating to your use of our Services. If we learn
    of a security system's breach, we may attempt to notify you electronically
    by posting a notice on our Services, by mail or by sending an email to you.
</p>

<p><b>7. INTERNATIONAL DATA TRANSFERS</b></p>
<p>
    All information processed by us may be transferred, processed, and stored
    anywhere in the world, including, but not limited to, the United States or
    other countries, which may have data protection laws that are different from
    the laws where you live. We endeavor to safeguard your information
    consistent with the requirements of applicable laws.
</p>

<p><b>8. RETENTION OF PERSONAL INFORMATION</b></p>
<p>
    We store the personal information we collect as described in this Privacy
    Policy for as long as you use our Services or as necessary to fulfill the
    purpose(s) for which it was collected, provide our Services, resolve
    disputes, establish legal defenses, conduct audits, pursue legitimate
    business purposes, enforce our agreements, and comply with applicable laws.
</p>

<p><b>Additional Privacy Rights</b></p>
<p>
    <b>Authorized Agent.</b> Only you, or someone legally authorized to act on your
    behalf, may make a verifiable consumer request related to your personal information.
    You may also make a verifiable consumer request on behalf of your minor child.
    To designate an authorized agent, please contact us as set forth in "contact
    us" below and provide written authorization signed by you and your designated
    agent.
</p>
<p>
    <b>Verification.</b> To protect your privacy, we will take steps the following
    steps to verify your identity before fulfilling your request. When you make a
    request, we will ask you to provide sufficient information that allows us to
    reasonably verify you are the person about whom we collected personal information
    or an authorized representative, which may include asking you to answer questions
    regarding your account and use of our Services.
</p>
<p>
    <b>Accessibility.</b> This Privacy Policy uses industry-standard technologies
    and was developed in line with the World Wide Web Consortium’s Web Content Accessibility
    Guidelines, version 2.1. If you wish to print this policy, please do so from
    your web browser or by saving the page as a PDF.
</p>

<p><b>9. CHILDREN'S INFORMATION</b></p>
<p>
    The Services are not directed to children under 13 (or other age as required
    by local law), and we do not knowingly collect personal information from
    children.
</p>
<p>
    If you are a parent or guardian and believe your child has uploaded personal
    information to our site without your consent, you may contact us as
    described in "Contact Us" below. If we become aware that a child has
    provided us with personal information in violation of applicable law, we
    will delete any personal information we have collected, unless we have a
    legal obligation to keep it, and terminate the child's account.
</p>

<p><b>10. OTHER PROVISIONS</b></p>
<p>
    <b>Third-Party Websites/Applications.</b> The Services may contain links to other
    websites/applications and other websites/applications may reference or link to
    our Services. These third-party services are not controlled by us. We encourage
    our users to read the privacy policies of each website and application with which
    they interact. We do not endorse, screen or approve, and are not responsible
    for, the privacy practices or content of such other websites or applications.
    Providing personal information to third-party websites or applications is at
    your own risk.
</p>
<p>
    <b>Supervisory Authority.</b> If you are located in the European Economic Area,
    Switzerland, the United Kingdom or Brazil, you have the right to lodge a complaint
    with a supervisory authority if you believe our processing of your personal information
    violates applicable law.
</p>
<p>
    <b>Changes to our Privacy Policy.</b> We may revise this Privacy Policy from
    time to time in our sole discretion. If there are any material changes to this
    Privacy Policy, we will notify you as required by applicable law. You understand
    and agree that you will be deemed to have accepted the updated Privacy Policy
    if you continue to use our Services after the new Privacy Policy takes effect.
</p>

<p><b>11. CONTACT US</b></p>
<p>
    If you have any questions about our privacy practices or this Privacy
    Policy, or to exercise your rights as detailed in this Privacy Policy,
    please contact us at:
</p>

<p>
    LearningQ<br />
    444 Kent Way<br />
    Zephyr Cove, NV 89448-0618<br />
    <a class="link" href="mailto:info@learningq.org"> info@learningq.org </a>
</p>

<style lang="scss" scoped>
    h1 {
        margin-bottom: 20px;
        font-size: 1.4rem;
    }
    .indent-1 {
        margin-left: 30px;
    }
</style>`
const Privacy = () => {
  return (
    <>
      <SEO title="Terms of Service" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
                         {/* Start Slider Area  */}
                         <div className="slider-area slider-style-3 height-750  bg_image bg_image_fixed" data-black-overlay="2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="order-2 order-lg-1 col-lg-8 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner pt--80 text-left">
                                    <h1 className="title pt--80 theme-gradient">Privacy Policy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}
          <div className="container">
         
          <Separator />
          { <div dangerouslySetInnerHTML={{ __html: rawHTML }} /> }
        </div>
   

        <FooterFour />
      </main>
    </>
  );
};

export default Privacy;
