import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionStudy = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Who is eligible to participate?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            LearningQ is looking for agencies and instructors who are currently
            offering programming in the areas of Workforce Basics and Healthy
            Cooking Basics to serve as our partners.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            What are the requirements for the Learners?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            LearningQ is a web-based platform. Our participants must have access
            to a computer, tablet, or cell phone to be able to use the content.
            Our sign-up process requires a unique email or text number to
            receive a registration link.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            How will my information be used?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            All data will be pulled anonymously and in aggregate. No
            identifiable information will be availible to the the Principal
            Investigator or used for reporting purposes.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            What are the goals of the research?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            To date, there is limited research on the benefits and usage of
            online learning, specifically microlearning, by people with
            disabilities. LearningQ believes the easily digestible format and
            repetition ideally provides for expansion of educational
            opportunities.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="4">
            How long does this program last?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            While program participants - instructors and learners - will have
            access to LearningQ through August, 2024, our research project only
            runs through November, 2023. We require that the participants use
            the LearningQ platform in September and October and data collection
            and reporting will be conducted in November by the LearningQ team.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="5">
            What are the benefits to the Agencies and Learners?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="5">
          <Card.Body>
            Our participants will be provided with a yearlong scholarship to the
            LearningQ curriculum. This allows the learners to revisit the
            courses as often as possible to reinforce any skill they desire
            anytime during the scholarship period.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionStudy;
