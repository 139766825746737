import React from "react";
import BrandThree from "./BrandOne";
import Separator from "../separator/Separator";

const BrandPolice = () => {
  return (
    <>
      <div className="main-content">
        <Separator />
        {/* Start Brand Area  */}

        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt--10">
              <BrandThree brandStyle="brand-style-2" />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area  */}
    </>
  );
};
export default BrandPolice;
