import React from "react";
import ContactForm from "./ContentForm";
import { FiMail } from "react-icons/fi";

const ContactOne = () => {
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="rn-address">
                  <br />
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Email Address</h4>
                    <p>
                      <a href="mailto:info@learningq.org">info@learningq.org</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-12">
                <ContactForm formStyle="contact-form-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactOne;
