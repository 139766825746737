import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderTwo';
import FooterFour from '../common/footer/FooterThree';
import TimelineOne from "../elements/timeline/TimelineOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";

const ContentCreation = () => {
    return (
        <>
            <SEO title="LearningQ | Content Creation | Developing Microlearning Content" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-3 height-750  bg_image bg_image_fixed" data-black-overlay="2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="inner text-left">
                                    <h1 className="title pt--80 theme-gradient">Create training<br/>that gets results.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Our expert content team can help organizations develop and produce their own unique content.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">The content development teams at LearningQ create, develop, and produce skills training curriculum that is engaging and effective. We work with content partner organizations to construct and produce their certification programs. We leverage the power in our micro-learning format to teach and reinforce life-skills.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    title = "Planning, Production, Partnership"
                                    description = "We offer a turn-key solution to content development. The LearningQ team will work with you to plan out exactly what you are looking for, then we will make sure it is brought to life through highly accessible skill units. Finally, LearningQ can partner with you, if you want, to find new audiences for your content."
                                    />
                            </div>
                        </div>
                        <TimelineOne 
                           
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}
                <Separator />
            
                <FooterFour />
            </main>
        </>
    )
}

export default ContentCreation;
